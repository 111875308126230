
export function isNullish (dataSet: any): boolean {
  if (dataSet === '') {
    return true
  }

  if (dataSet === null) {
    return true
  }

  if (dataSet === undefined) {
    return true
  }

  if (typeof dataSet === 'object' && Object.keys(dataSet).length === 0) {
    return true
  }

  if (Array.isArray(dataSet) && dataSet.length === 0) {
    return true
  }

  return false
}
