import * as Sentry from '@sentry/react'
import { delay, put, takeLatest, throttle } from 'redux-saga/effects';
import { addNotification } from '../actions/notification';
import { NOTIFICATION_STATUS_ERROR } from '../types/Notification';
import { Action } from 'redux-saga';

export function* failureSaga(): Generator<any, void, unknown> {
  yield takeLatest((action: Action) => (/.*_FAILURE$/g).test(action.type), handleFailure)
}

export function* handleFailure(action: any): Generator<any, void, unknown> {
  const err = action.err !== undefined ? action.err : action.error

  console.error(err)
  const eventId = Sentry.captureException(err)

  // Debounce failure notifications by 500ms
  // If multiples errors come at the same time, takeLatest() will cancel currently running sagas and
  // use only the latest
  yield delay(500)

  const message = typeof err?.getUserMessage === 'function' ? err?.getUserMessage() : `Une erreur inconnue est survenue ! (ID: ${eventId})`

  yield put(addNotification({ message, status: NOTIFICATION_STATUS_ERROR }));
}
