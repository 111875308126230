import { call, put } from 'redux-saga/effects'
import { MAIN_DRAWER_FAILURE, MAIN_DRAWER_SUCCESS } from '../actions/drawer'

export function* toggleMainDrawerSaga() {
  let result
  try {
    result = yield call(getToggle)
  } catch (err) {
    yield put({ type: MAIN_DRAWER_FAILURE, err })
    return
  }

  yield put({ type: MAIN_DRAWER_SUCCESS, result })
}

function getToggle() {
  return true
}
