import { assertResponse } from './response'

export async function fetchWithCredentials(idToken: string, url: string, init: RequestInit = {}): Promise<Response> {
  const params: RequestInit = {
    ...init,
    credentials: 'include',
    headers: {
      ...init.headers,
      Authorization: `Bearer ${idToken}`
    }
  }

  return await fetch(url, params)
    .then(res => {
      assertResponse(res)
      return res
    })
}
