import { Action } from 'redux'
import { ObjectFilter, PrivacyDomain, QueryObjectsOptions } from '../util/objects'
import { createObject, CreateObjectRequestAction, deleteObject, DeleteObjectRequestAction, fetchObject, FetchObjectRequestAction, queryObjects, QueryObjectsRequestAction, updateObject, UpdateObjectRequestAction } from './objects'
import { Record } from '../util/records'
import { ConsolidatedPatient } from '../types/shared/v1/ConsolidatedPatient';
import { allKeys } from '../util/type';
import { ConsolidatedPatientV2 } from '../types/shared/v2/Patient';

export const PATIENT_SET_CURRENT = 'PATIENT_SET_CURRENT'

export const PATIENT_SET_CURRENT_PROPS = 'PATIENT_SET_CURRENT_PROPS'

export interface PatientAction extends Action {
  method: string
  params: any
}

export interface PatientListAction extends PatientAction {
  records: Record[]
}

export interface CurrentPatientAction extends PatientAction {
  patient: string
}

export interface CurrentPatientPropsAction extends PatientAction {
  props: any
}

export function setCurrentPatient(patient: string): any {
  return { type: PATIENT_SET_CURRENT, patient }
}

export function setCurrentPatientProps(props: any): any {
  return { type: PATIENT_SET_CURRENT_PROPS, props }
}

export const PATIENT_NAMESPACE = 'patient-v2'

export function fetchPublicPatient(patientId: string): FetchObjectRequestAction {
  return fetchObject(PATIENT_NAMESPACE, PrivacyDomain.Public, patientId)
}

export function queryPublicPatients(filter?: ObjectFilter, options?: QueryObjectsOptions): QueryObjectsRequestAction {
  return queryObjects(PATIENT_NAMESPACE, PrivacyDomain.Public, filter, options)
}

export function updatePublicPatient(objectId: string, patient: ConsolidatedPatientV2): UpdateObjectRequestAction {
  return updateObject(PATIENT_NAMESPACE, PrivacyDomain.Public, objectId, patient)
}

export function createPublicPatient(patient: ConsolidatedPatientV2): CreateObjectRequestAction {
  return createObject(PATIENT_NAMESPACE, PrivacyDomain.Public, patient)
}

export function deletePublicPatient(patientId: string): DeleteObjectRequestAction {
  return deleteObject(PATIENT_NAMESPACE, PrivacyDomain.Public, patientId)
}

export const SAVE_EXPORTED_SAMPLE_REQUEST = 'SAVE_EXPORTED_SAMPLE_REQUEST'
export const SAVE_EXPORTED_SAMPLE_REQUEST_SUCCESS = 'SAVE_EXPORTED_SAMPLE_REQUEST_SUCCESS'
export const SAVE_EXPORTED_SAMPLE_REQUEST_FAILURE = 'SAVE_EXPORTED_SAMPLE_REQUEST_FAILURE'

export interface ExportedSampleAction extends Action {
  patient: ConsolidatedPatientV2
}

export function exportedSample(patient: ConsolidatedPatientV2): ExportedSampleAction {
  return { type: SAVE_EXPORTED_SAMPLE_REQUEST, patient }
}

export const SAVE_PATIENT_TO_OBJECT_SERVER_REQUEST = 'SAVE_PATIENT_TO_OBJECT_SERVER_REQUEST'
export const SAVE_PATIENT_TO_OBJECT_SERVER_REQUEST_SUCCESS = 'SAVE_PATIENT_TO_OBJECT_SERVER_REQUEST_SUCCESS'
export const SAVE_PATIENT_TO_OBJECT_SERVER_REQUEST_FAILURE = 'SAVE_PATIENT_TO_OBJECT_SERVER_REQUEST_FAILURE'

export interface SavePatientToObjectServerAction extends Action {
  patient: ConsolidatedPatientV2
}

export function fetchPatientByInlogId(inlogId: string): QueryObjectsRequestAction {
  return queryObjects(PATIENT_NAMESPACE, PrivacyDomain.Public, { eq: { inlogId } }, { limit: 1, hydrate: true })
}