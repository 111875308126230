import { Action } from 'redux'
import { FiltersDateAddSuccessAction, FILTER_DATE_ADD, FILTER_DATE_REMOVE, FILTER_DATE_REMOVE_ALL } from '../actions/filters'

export interface HistogramSeriesState {
  series: object[]
};

const defaultState = {
  series: []
}

export function histogramSeriesReducer (state = defaultState, action: Action) {
  switch (action.type) {
    case FILTER_DATE_ADD:
      return handleFilterAddDate(state, action as FiltersDateAddSuccessAction)
    case FILTER_DATE_REMOVE:
			  return handleFilterRemoveDate(state, action as FiltersDateAddSuccessAction)
    case FILTER_DATE_REMOVE_ALL:
      return handleFilterRemoveAllDate(state, action as FiltersDateAddSuccessAction)
  };

  return state
}

function handleFilterAddDate (state: HistogramSeriesState, action: FiltersDateAddSuccessAction) {
  return {
    ...state
  }
}

function handleFilterRemoveDate (state: HistogramSeriesState, action: FiltersDateAddSuccessAction) {
  return {
    ...state
  }
}

function handleFilterRemoveAllDate (state: HistogramSeriesState, action: FiltersDateAddSuccessAction) {
  return {
    ...state,
    series: []
  }
}
