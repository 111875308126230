import { Action } from 'redux'
import { ObjectFilter, PrivacyDomain, QueryObjectsOptions, StoreObject } from '../util/objects'
import { createObject, CreateObjectRequestAction, deleteObject, DeleteObjectRequestAction, fetchObject, FetchObjectRequestAction, queryObjects, QueryObjectsRequestAction, updateObject, UpdateObjectRequestAction } from './objects'
import { WorklistV2 } from '../types/shared/v2/Worklist'

export const WORKLIST_NAMESPACE = 'worklist-v2'

export function fetchPublicWorklist(worklistId: string): FetchObjectRequestAction {
  return fetchObject(WORKLIST_NAMESPACE, PrivacyDomain.Public, worklistId)
}

export function queryPublicWorklists(filter?: ObjectFilter, options?: QueryObjectsOptions): QueryObjectsRequestAction {
  return queryObjects(WORKLIST_NAMESPACE, PrivacyDomain.Public, filter, options)
}

export function updatePublicWorklist(workListId: string, worklist: WorklistV2): UpdateObjectRequestAction {
  return updateObject(WORKLIST_NAMESPACE, PrivacyDomain.Public, workListId, worklist)
}

export function createPublicWorklist(worklist: WorklistV2): CreateObjectRequestAction {
  return createObject(WORKLIST_NAMESPACE, PrivacyDomain.Public, worklist)
}

export function deletePublicWorklist(worklistId: string): DeleteObjectRequestAction {
  return deleteObject(WORKLIST_NAMESPACE, PrivacyDomain.Public, worklistId)
}

export interface ExportWorkListRequestAction extends Action {
  worklist: StoreObject<WorklistV2>
}

export const EXPORT_WORKLIST_REQUEST = 'EXPORT_WORKLIST_REQUEST'
export const EXPORT_WORKLIST_SUCCESS = 'EXPORT_WORKLIST_SUCCESS'
export const EXPORT_WORKLIST_FAILURE = 'EXPORT_WORKLIST_FAILURE'

export function exportWorklist(worklist: StoreObject<WorklistV2>): ExportWorkListRequestAction {
  return { type: EXPORT_WORKLIST_REQUEST, worklist }
}
