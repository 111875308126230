import { Action } from 'redux'

export const FILTER_DATE_ADD = 'FILTER_DATE_ADD'
export const FILTER_DATE_REMOVE = 'FILTER_DATE_REMOVE'
export const FILTER_DATE_REMOVE_ALL = 'FILTER_DATE_REMOVE_ALL'

export interface FiltersAction extends Action {
  method: string
  params: any
}

export interface FiltersDateAddSuccessAction extends FiltersAction {
  date: string
}

export function filtersDateAdd (date: string) {
  return { type: FILTER_DATE_ADD, date }
}

export function filtersDateRemove (date: string) {
  return { type: FILTER_DATE_REMOVE, date }
}

export function filtersDateRemoveAll () {
  return { type: FILTER_DATE_REMOVE_ALL }
}
