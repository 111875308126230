import { Action } from "redux";
import { ToggleSuccessAction, MAIN_DRAWER_SUCCESS } from "../actions/drawer";

export interface CurrentDrawersState {
  mainDrawer: {
    toggle: boolean;
  };
  thresholdsDrawer: {
    toggle: boolean;
  };
}

const defaultState = {
  mainDrawer: {
    toggle: false,
  },
  thresholdsDrawer: {
    toggle: false,
  },
};

export function drawersReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case MAIN_DRAWER_SUCCESS:
      return handleMainDrawerToggle(state, action as ToggleSuccessAction);
  }

  return state;
}

function handleMainDrawerToggle(
  state: CurrentDrawersState,
  action: ToggleSuccessAction
) {
  const isVisible = !state.mainDrawer.toggle;
  return {
    ...state,
    mainDrawer: {
      toggle: isVisible,
    },
    thresholdsDrawer: {
      toggle: isVisible ? false : state.thresholdsDrawer.toggle,
    },
  };
}
