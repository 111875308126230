import { Action } from 'redux'
import { CristalAction, CristalSuccessAction, ToggleHorizontalMaxSuccessAction, CRISTAL_DATA_SUCCESS, CRISTAL_HORIZONTAL_MAX_TOGGLE_SUCCESS } from '../actions/cristal'

export interface CurrentCristalState {
  json: any
  horizontalMax: boolean
};

const defaultState = {
  json: [{}],
  horizontalMax: false
}

export function cristalReducer (state = defaultState, action: Action) {
  switch (action.type) {
    case CRISTAL_DATA_SUCCESS:
      return handleCristalDataSuccess(state, action as CristalSuccessAction)
    case CRISTAL_HORIZONTAL_MAX_TOGGLE_SUCCESS:
      return handleToggleHorizontalMaximizeSuccess(state, action as ToggleHorizontalMaxSuccessAction)
  };

  return state
}

function handleCristalDataSuccess (state: CurrentCristalState, action: CristalSuccessAction) {
  return {
    ...state,
    json: action.result
  }
}

function handleToggleHorizontalMaximizeSuccess (state: CurrentCristalState, action: ToggleHorizontalMaxSuccessAction) {
  return {
    ...state,
    horizontalMax: !state.horizontalMax
  }
}
