/** 
 * !!! DO NOT MODIFY !!!
 * 
 * File generated from types definition in project https://forge.cadoles.com/EFS/sheila.
 *
 * See misc/script/generate-type-defs.sh script for more informations.
 **/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypingGroup = exports.TypingType = void 0;
var TypingType;
(function (TypingType) {
    TypingType["TYPAGE_SERO"] = "TYPAGE_SERO";
    TypingType["TYPAGE_BM"] = "TYPAGE_BM";
})(TypingType || (exports.TypingType = TypingType = {}));
var TypingGroup;
(function (TypingGroup) {
    TypingGroup["A"] = "A";
    TypingGroup["B"] = "B";
    TypingGroup["Bw"] = "Bw";
    TypingGroup["C"] = "C";
    TypingGroup["DR"] = "DR";
    TypingGroup["DRB1"] = "DRB1";
    TypingGroup["DRB2"] = "DRB2";
    TypingGroup["DRB3"] = "DRB3";
    TypingGroup["DRB4"] = "DRB4";
    TypingGroup["DRB5"] = "DRB5";
    TypingGroup["DQ"] = "DQ";
    TypingGroup["DQB1"] = "DQB1";
    TypingGroup["DQA1"] = "DQA1";
    TypingGroup["DP"] = "DP";
    TypingGroup["DPB1"] = "DPB1";
    TypingGroup["DPA1"] = "DPA1";
})(TypingGroup || (exports.TypingGroup = TypingGroup = {}));
