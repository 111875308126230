export class ExtendableError extends Error {
  constructor(message) {
    super(message)
    Object.setPrototypeOf(this, ExtendableError.prototype)
    this.name = this.constructor.name
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = (new Error(message)).stack
    }
  }
}

export class InvalidRequestError extends ExtendableError {
  response: any

  constructor(response: any) {
    super('Invalid request')
    Object.setPrototypeOf(this, InvalidRequestError.prototype)
    this.response = response
  }

  getResponse(): any {
    return this.response
  }

  getUserMessage() {
    return `Le serveur a indiqué que la requête était invalide !`
  }
}

export class UnauthorizedRequestError extends ExtendableError {
  response: any

  constructor(response: any) {
    super('Unauthorized request')
    Object.setPrototypeOf(this, UnauthorizedRequestError.prototype)
    this.response = response
  }

  getResponse(): any {
    return this.response
  }

  getUserMessage() {
    return `Le serveur a indiqué que l'utilisateur n'était pas authentifié !`
  }
}

export class ForbiddenRequestError extends ExtendableError {
  response: any

  constructor(response: any) {
    super('Forbidden request')
    Object.setPrototypeOf(this, ForbiddenRequestError.prototype)
    this.response = response
  }

  getResponse(): any {
    return this.response
  }

  getUserMessage() {
    return `Le serveur a indiqué que l'opération était interdite à l'utilisateur !`
  }
}

export class ServerError extends ExtendableError {
  response: Response

  constructor(response: Response) {
    super(`Server Error: ${response.status} - ${response.statusText}`)
    Object.setPrototypeOf(this, ServerError.prototype)
    this.response = response
  }

  getResponse(): any {
    return this.response
  }

  getUserMessage() {
    return `Une erreur est survenue lors de l'interrogation du serveur ! (${this.response.status} - ${this.response.statusText})`
  }
}
