import { Action } from 'redux'
import { Roles } from '../types/Session'

export const USER_LOGIN_REQUEST_REQUEST = 'USER_LOGIN_REQUEST_REQUEST'
export const USER_LOGIN_REQUEST_SUCCESS = 'USER_LOGIN_REQUEST_SUCCESS'
export const USER_LOGIN_REQUEST_FAILURE = 'USER_LOGIN_REQUEST_FAILURE'

export const USER_LOGOUT_REQUEST_REQUEST = 'USER_LOGOUT_REQUEST_REQUEST'
export const USER_LOGOUT_REQUEST_SUCCESS = 'USER_LOGOUT_REQUEST_SUCCESS'
export const USER_LOGOUT_REQUEST_FAILURE = 'USER_LOGOUT_REQUEST_FAILURE'

export const USER_LOGIN_CHECK_REQUEST = 'USER_LOGIN_CHECK_REQUEST'
export const USER_LOGIN_CHECK_SUCCESS = 'USER_LOGIN_CHECK_SUCCESS'
export const USER_LOGIN_CHECK_FAILURE = 'USER_LOGIN_CHECK_FAILURE'

export const REFRESH_ACCESS_TOKEN_REQUEST = 'REFRESH_ACCESS_TOKEN_REQUEST'
export const REFRESH_ACCESS_TOKEN_SUCCESS = 'REFRESH_ACCESS_TOKEN_SUCCESS'
export const REFRESH_ACCESS_TOKEN_FAILURE = 'REFRESH_ACCESS_TOKEN_FAILURE'

export const USER_LOGIN_INFO_REQUEST = 'USER_LOGIN_INFO_REQUEST'
export const USER_LOGIN_INFO_REQUEST_SUCCESS = 'USER_LOGIN_INFO_REQUEST_SUCCESS'
export const USER_LOGIN_INFO_REQUEST_FAILURE = 'USER_LOGIN_INFO_REQUEST_FAILURE'

export interface SessionAction extends Action {
  method: string
  params: any
}

export interface UserLoginRequest extends SessionAction {
  result: any
}

export interface UserLogoutRequest extends SessionAction {}

export interface UserLoginCheck extends SessionAction {
  result: any
}

export interface UserSession extends SessionAction {
  result: any
}

export function userLoginRequest (): any {
  return { type: USER_LOGIN_REQUEST_REQUEST }
}

export function userLogoutRequest (): any {
  return { type: USER_LOGOUT_REQUEST_REQUEST }
}

export function userLoginCheck (): any {
  return { type: USER_LOGIN_CHECK_REQUEST }
}

export interface RefreshAccessTokenSuccess extends Action {
  refreshToken: string
  accessToken: string
}

export function refreshAccessToken (): Action {
  return { type: REFRESH_ACCESS_TOKEN_REQUEST }
}

export interface UserInfoRequestAction extends SessionAction {
  result: UserInfoRequestResult
}

interface UserInfoRequestResult {
  Data: {
    Tenant: string
    User: {
      Roles: Roles
      Subject: string
    }
  }
}
