import { Action } from 'redux'
import { Object, ObjectFilter, ObjectHeader, PrivacyDomain, QueryObjectsOptions, StoreObject } from '../util/objects'
import { EventObject } from '../util/events'

export interface ObjectAction extends Action {
  namespace: string
  privacyDomain: PrivacyDomain
}

export interface ObjectOpSuccessAction<T = any> extends ObjectAction {
  object: Object<T>
}

export interface ObjectDeleteSuccessAction<T = any> extends ObjectAction {
  id: string
}

export interface QueryObjectsSuccessAction extends ObjectAction {
  objects: (ObjectHeader | StoreObject)[]
}

export interface QueryObjectsFailureAction extends ObjectAction {
  err: Error
}

export interface QueryObjectsRequestAction extends ObjectAction {
  filter?: ObjectFilter
  options?: QueryObjectsOptions
}

export const QUERY_OBJECTS_REQUEST = 'QUERY_OBJECTS_REQUEST'
export const QUERY_OBJECTS_SUCCESS = 'QUERY_OBJECTS_SUCCESS'
export const QUERY_OBJECTS_FAILURE = 'QUERY_OBJECTS_FAILURE'

export function queryObjects(namespace: string, privacyDomain: PrivacyDomain, filter?: ObjectFilter, options?: QueryObjectsOptions): QueryObjectsRequestAction {
  return { type: QUERY_OBJECTS_REQUEST, namespace, privacyDomain, filter, options }
}

export interface FetchObjectRequestAction extends ObjectAction {
  id: string
}

export const FETCH_OBJECT_REQUEST = 'FETCH_OBJECT_REQUEST'
export const FETCH_OBJECT_SUCCESS = 'FETCH_OBJECT_SUCCESS'
export const FETCH_OBJECT_FAILURE = 'FETCH_OBJECT_FAILURE'

export function fetchObject(namespace: string, privacyDomain: PrivacyDomain, id: string): FetchObjectRequestAction {
  return { type: FETCH_OBJECT_REQUEST, namespace, privacyDomain, id }
}

export interface FetchObjectRequestAction extends ObjectAction {
  id: string
}


export interface CreateObjectRequestAction<T = any> extends ObjectAction {
  object: T
}

export const CREATE_OBJECT_REQUEST = 'CREATE_OBJECT_REQUEST'
export const CREATE_OBJECT_SUCCESS = 'CREATE_OBJECT_SUCCESS'
export const CREATE_OBJECT_FAILURE = 'CREATE_OBJECT_FAILURE'

export function createObject(namespace: string, privacyDomain: PrivacyDomain, object: any): CreateObjectRequestAction {
  return { type: CREATE_OBJECT_REQUEST, namespace, privacyDomain, object }
}

export interface UpdateObjectRequestAction<T = any> extends ObjectAction {
  id: string
  object: Partial<T>
  partial: boolean
}

export const UPDATE_OBJECT_REQUEST = 'UPDATE_OBJECT_REQUEST'
export const UPDATE_OBJECT_SUCCESS = 'UPDATE_OBJECT_SUCCESS'
export const UPDATE_OBJECT_FAILURE = 'UPDATE_OBJECT_FAILURE'

export function updateObject<T>(namespace: string, privacyDomain: PrivacyDomain, id: string, object: Partial<T>, partial: boolean = false): UpdateObjectRequestAction<T> {
  return { type: UPDATE_OBJECT_REQUEST, namespace, privacyDomain, id, object, partial }
}

export interface DeleteObjectRequestAction extends ObjectAction {
  id: string
}

export const DELETE_OBJECT_REQUEST = 'DELETE_OBJECT_REQUEST'
export const DELETE_OBJECT_SUCCESS = 'DELETE_OBJECT_SUCCESS'
export const DELETE_OBJECT_FAILURE = 'DELETE_OBJECT_FAILURE'

export function deleteObject(namespace: string, privacyDomain: PrivacyDomain, id: string): DeleteObjectRequestAction {
  return { type: DELETE_OBJECT_REQUEST, namespace, privacyDomain, id }
}

export const FETCH_OBJECT_EVENT_REQUEST = 'FETCH_OBJECT_EVENT_REQUEST'
export const FETCH_OBJECT_EVENT_SUCCESS = 'FETCH_OBJECT_EVENT_SUCCESS'
export const FETCH_OBJECT_EVENT_FAILURE = 'FETCH_OBJECT_EVENT_FAILURE'

export function fetchObjectEvents(namespace: string, privacyDomain: PrivacyDomain, id: string, object: any): FetchObjectEventsRequestAction {
  return { type: FETCH_OBJECT_EVENT_REQUEST, namespace, privacyDomain, id, object }
}

export interface FetchObjectEventsRequestAction<T = any> extends ObjectAction {
  id: string,
  object: T,
}

export interface ObjectEventsSuccessAction<T = any> extends ObjectAction {
  object: T,
  objects: EventObject[]
}
