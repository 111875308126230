import { Action } from 'redux'
import { FiltersDateAddSuccessAction, FILTER_DATE_ADD, FILTER_DATE_REMOVE, FILTER_DATE_REMOVE_ALL } from '../actions/filters'

export interface FiltersState {
  dates: string[]
};

const defaultState = {
  dates: []
}

export function filtersReducer (state = defaultState, action: Action) {
  switch (action.type) {
    case FILTER_DATE_ADD:
      return handleFilterAddDate(state, action as FiltersDateAddSuccessAction)
    case FILTER_DATE_REMOVE:
			  return handleFilterRemoveDate(state, action as FiltersDateAddSuccessAction)
    case FILTER_DATE_REMOVE_ALL:
      return handleFilterRemoveAllDate(state, action as FiltersDateAddSuccessAction)
  };

  return state
}

function handleFilterAddDate (state: FiltersState, action: FiltersDateAddSuccessAction) {
  return {
    ...state,
    dates: [...state.dates, action.date]
  }
}

function handleFilterRemoveDate (state: FiltersState, action: FiltersDateAddSuccessAction) {
  return {
    ...state,
    dates: state.dates.filter(date => date !== action.date)
  }
}

function handleFilterRemoveAllDate (state: FiltersState, action: FiltersDateAddSuccessAction) {
  return {
    ...state,
    dates: []
  }
}
