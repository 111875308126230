import { InvalidRequestError, ServerError } from './error'
import { fetchWithCredentials } from './fetch'

export interface Filesystem {
    name: string
    dsn: string
    metadata: { [key: string]: any } | null
}

export interface Watcher {
    filesystemEndpoint: string
    directory: string
    events: string[]
    pipelineName: string
    filter: string
    interval: string
}

export class FileServerClient {
    baseUrl: string
    idToken: string

    constructor(baseUrl: string, idToken: string) {
        this.baseUrl = baseUrl
        this.idToken = idToken

        // Force bind methods to instance
        this.fetchFilesystem = this.fetchFilesystem.bind(this)
        this.queryFilesystems = this.queryFilesystems.bind(this)
        this.queryWatchers = this.queryWatchers.bind(this)
    }

    async fetchFilesystem(name: string): Promise<Filesystem> {
        const url = `${this.baseUrl}/api/v1/filesystems/${name}`
        const res = await fetchWithCredentials(this.idToken, url)
        const result = await res.json()

        return itemToFilesystem(result.Data.Filesystem)
    }

    async queryFilesystems(): Promise<Filesystem[]> {
        const url = `${this.baseUrl}/api/v1/filesystems`
        const res = await fetchWithCredentials(this.idToken, url)
        const result = await res.json()

        return result.Data.Filesystems.map(item => itemToFilesystem(item))
    }

    async queryWatchers(): Promise<Filesystem[]> {
        const url = `${this.baseUrl}/api/v1/watchers`
        const res = await fetchWithCredentials(this.idToken, url)
        const result = await res.json()

        return result.Data.Watchers.map(item => itemToWatcher(item))
    }
}

function itemToFilesystem(item: any): Filesystem {
    return {
        name: item.Name,
        dsn: item.DSN,
        metadata: item.Metadata
    }
}

function itemToWatcher(item: any): Watcher {
    return {
        filesystemEndpoint: item.FilesystemEndpoint,
        directory: item.Directory,
        events: item.Events,
        pipelineName: item.PipelineName,
        filter: item.Filter,
        interval: item.Interval
    }
}
