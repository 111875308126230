import { Action } from "redux";
import { QueryObjectsRequestAction } from "./objects";
import {
  Object,
  ObjectFilter,
  ObjectHeader,
  PrivacyDomain,
  QueryObjectsOptions,
  StoreObject,
} from "../util/objects";
import { hydrate } from "react-dom";

export const SEARCH_OBJECT_REQUEST = "SEARCH_OBJECT_REQUEST";
export const SEARCH_OBJECT_REQUEST_SUCCESS = "SEARCH_OBJECT_REQUEST_SUCCESS";
export const SEARCH_OBJECT_REQUEST_FAILURE = "SEARCH_OBJECT_REQUEST_FAILURE";

export interface SearchAction extends Action {
  queries: SearchQuery[];
}

export interface SearchQuery {
  namespace: string;
  privacyDomain: PrivacyDomain;
  filter: ObjectFilter;
  asRef?: SearchReference;
}

export interface SearchReference<T = any> {
  targetNamespace: string;
  targetPrivacyDomain: PrivacyDomain;
  targetFilter: (obj: StoreObject<T>) => ObjectFilter;
}

export interface SearchResultAction<T = any> extends SearchAction {
  results: SearchResult<T>[];
}

export interface SearchResult<T = any> extends StoreObject<T> {
  namespace: string;
  privacyDomain: PrivacyDomain;
}

export function searchObjectRequest(...queries: SearchQuery[]): SearchAction {
  return { type: SEARCH_OBJECT_REQUEST, queries };
}
