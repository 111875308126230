/** 
 * !!! DO NOT MODIFY !!!
 * 
 * File generated from types definition in project https://forge.cadoles.com/EFS/sheila.
 *
 * See misc/script/generate-type-defs.sh script for more informations.
 **/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalysisResultGroupThresholdOperator = exports.AnalysisResultGroupType = void 0;
var AnalysisResultGroupType;
(function (AnalysisResultGroupType) {
    // Groupes utilisés pour catégoriser les résultats des MFIs sur une analyse donnée
    AnalysisResultGroupType["Punctual"] = "punctual";
    // Groupes utilisés pour classifier de manière binaire des MFIs
    AnalysisResultGroupType["Binary"] = "binary";
    // Groupes utilisés pour classifier un historique de résultats sur une spécificité donnée
    AnalysisResultGroupType["Historized"] = "historized";
    AnalysisResultGroupType["DSA_CLASS1"] = "dsa-class1";
    AnalysisResultGroupType["DSA_CLASS2"] = "dsa-class2";
    AnalysisResultGroupType["Crossmatch"] = "crossmatch";
})(AnalysisResultGroupType || (exports.AnalysisResultGroupType = AnalysisResultGroupType = {}));
var AnalysisResultGroupThresholdOperator;
(function (AnalysisResultGroupThresholdOperator) {
    AnalysisResultGroupThresholdOperator["GT"] = ">";
    AnalysisResultGroupThresholdOperator["GTE"] = ">=";
    AnalysisResultGroupThresholdOperator["LT"] = "<";
    AnalysisResultGroupThresholdOperator["LTE"] = "<=";
    AnalysisResultGroupThresholdOperator["EQ"] = "==";
})(AnalysisResultGroupThresholdOperator || (exports.AnalysisResultGroupThresholdOperator = AnalysisResultGroupThresholdOperator = {}));
