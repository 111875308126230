import { Action } from 'redux'
import { CLEAR_TRANSACTIONS } from '../hooks/useTransaction'

export interface Transaction {
  loading: boolean
  started: boolean
  done: boolean
  results?: any[]
  errors?: Error[] | any[]
}

export interface TransactionsState {
  byId: {
    [id: string]: Transaction
  }
}

const defaultState = {
  byId: {}
}

export interface TxAction extends Action {
  txId: string
}

export interface ClearTransactionsAction extends Action {
  txIds: string[]
}

export function transactionsReducer (state = defaultState, action: TxAction): TransactionsState {
  if (action.type === CLEAR_TRANSACTIONS) {
    return handleClearTransactions(state, action)
  }

  if (action.txId === null) return state

  if (/_REQUEST$/g.test(action.type)) {
    return handleTransactionRequest(state, action)
  } else if (/_SUCCESS$/g.test(action.type)) {
    return handleTransactionSuccess(state, action)
  } else if (/_FAILURE$/g.test(action.type)) {
    return handleTransactionFailure(state, action)
  }

  return state
}

function handleTransactionRequest (state: TransactionsState, action: TxAction): TransactionsState {
  return {
    ...state,
    byId: {
      ...state.byId,
      [action.txId]: {
        loading: true,
        started: true,
        done: false,
        errors: undefined,
        results: undefined
      }
    }
  }
}

function handleTransactionSuccess (state: TransactionsState, action: TxAction): TransactionsState {
  return {
    ...state,
    byId: {
      ...state.byId,
      [action.txId]: {
        loading: false,
        started: true,
        done: true,
        errors: undefined,
        results: Array.isArray(state.byId[action.txId]?.results) ? [...(state.byId[action.txId]?.results ?? []), action] : [action]
      }
    }
  }
}

function handleTransactionFailure (state: TransactionsState, action: TxAction): TransactionsState {
  return {
    ...state,
    byId: {
      ...state.byId,
      [action.txId]: {
        loading: false,
        started: true,
        done: true,
        results: undefined,
        errors: Array.isArray(state.byId[action.txId]?.errors) ? [...(state.byId[action.txId]?.errors ?? []), (action as any).error] : [(action as any).error]
      }
    }
  }
}

function handleClearTransactions (state: TransactionsState, action: any): TransactionsState {
  const newState = {
    ...state,
    byId: {
      ...state.byId
    }
  }

  action.txIds.forEach(txId => {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete newState.byId[txId]
  })

  return newState
}
