import { Action } from 'redux'
import { TENANTS_DATA_REQUEST_SUCCESS, Tenant, TenantsRequestDataSuccessAction, TENANT_DATA_REQUEST_SUCCESS, GET_ROLE_REQUEST_SUCCESS, GetRoleSuccessAction, TenantRequestDataSuccessAction, ADD_ROLE_REQUEST_SUCCESS, REMOVE_ROLE_REQUEST_SUCCESS } from '../actions/tenant'

export interface TenantState {
  byName: IndexedTenants
  users: UserByTenant
  roleByUser: RolesByUser
}

export interface IndexedTenants {
  [key: string]: Tenant
}

export interface UserByTenant {
  [key: string]: TenantUser
}

export interface TenantUser {
  Subjects: string[]
}

export interface RolesByUser {
  [key: string]: string[]
}

const defaultState: TenantState = {
  byName: {},
  users: {},
  roleByUser: {}
}

export function tenantReducer (state = defaultState, action: Action): TenantState {
  switch (action.type) {
    case TENANTS_DATA_REQUEST_SUCCESS:
      return handleTenantsList(state, action as TenantsRequestDataSuccessAction)
    case TENANT_DATA_REQUEST_SUCCESS:
      return handleTenant(state, action as TenantRequestDataSuccessAction)
    case GET_ROLE_REQUEST_SUCCESS:
      return handleRole(state, action as GetRoleSuccessAction)
    case ADD_ROLE_REQUEST_SUCCESS:
      return handleRole(state, action as GetRoleSuccessAction)
    case REMOVE_ROLE_REQUEST_SUCCESS:
      return handleRole(state, action as GetRoleSuccessAction)
  };

  return state
}

function handleTenantsList (state: TenantState, action: TenantsRequestDataSuccessAction): TenantState {
  const tenantsResult = action.result.Data.Tenants
  const tenants: { [byName: string]: Tenant} = {}
  tenantsResult.forEach((tenant: Tenant) => {
    tenants[tenant.Name] = {
      Name: tenant.Name,
      Title: tenant.Title
    }
  })
  return {
    ...state,
    byName: tenants
  }
}

function handleTenant (state: TenantState, action: TenantRequestDataSuccessAction): TenantState {
  const tenant = action.result.Data.Tenant
  const newState = { ...state }
  newState.users[tenant.Name] = { Subjects: tenant.Subjects }
  return newState
}

function handleRole (state: TenantState, action: GetRoleSuccessAction): TenantState {
  const newState = { ...state }
  newState.roleByUser[action.subject] = action.result.Data.Roles
  return newState
}
