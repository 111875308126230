import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { RUN_PIPELINE_FAILURE, RUN_PIPELINE_REQUEST, RUN_PIPELINE_SUCCESS, RunPipelineFailureAction, RunPipelineRequestAction, RunPipelineSuccessAction } from "../actions/pipeline";
import { forwardTx } from "../hooks/useTransaction";
import { selectIdToken } from "../selectors/session";
import { Config } from "../config";
import { Pipeline, PipelineRun, PipelineRunState, getPipelineRuneStateLabel } from '../types/Pipeline';
import { PipelineServerClient } from "../util/pipelines";

export function* pipelineSaga(): Generator<any, any, any> {
    yield all([
        takeEvery(RUN_PIPELINE_REQUEST, runPipelineSaga)
    ])
}

function* runPipelineSaga(action: RunPipelineRequestAction): Generator<any, void, never> {
    const idToken = yield select(selectIdToken)

    const client = new PipelineServerClient(Config.pipelineServerURL, idToken as string)

    let pipelineRun!: PipelineRun
    try {
        const pipeline: Pipeline = yield call(client.fetchPipelineByName, action.name)
        pipelineRun = yield call(client.runPipeline, pipeline.ID, action.input)
        pipelineRun = yield call(client.waitForRun, pipeline.ID, pipelineRun.ID)
        if (pipelineRun.State !== PipelineRunState.Success) {
            throw new Error(`Unexpected pipeline run state '${getPipelineRuneStateLabel(pipelineRun.State)}' !`)
        }
    } catch (err) {
        const failure: RunPipelineFailureAction = {
            ...action,
            type: RUN_PIPELINE_FAILURE,
            pipelineRunId: pipelineRun?.ID ?? undefined,
            error: err,
        }

        yield put(forwardTx(action, failure))
        return
    }

    const success: RunPipelineSuccessAction = {
        ...action,
        type: RUN_PIPELINE_SUCCESS,
        pipelineRunId: pipelineRun?.ID,
    }

    yield put(forwardTx(action, success))
    return
}



