import { Action } from "redux";

export const MAIN_DRAWER_REQUEST = "MAIN_DRAWER_REQUEST";
export const MAIN_DRAWER_SUCCESS = "MAIN_DRAWER_SUCCESS";
export const MAIN_DRAWER_FAILURE = "MAIN_DRAWER_FAILURE";

export interface DrawerAction extends Action {
  method: string;
  params: any;
}

export interface ToggleSuccessAction extends DrawerAction {
  result: any;
}

export function toggleMainDrawer() {
  return { type: MAIN_DRAWER_REQUEST };
}
