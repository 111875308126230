import { ForbiddenRequestError, InvalidRequestError, ServerError, UnauthorizedRequestError } from "./error"

export function assertResponse(res: Response, result?: any) {
  if (res.status === 400) {
    throw new InvalidRequestError(result)
  }

  if (res.status === 401) {
    throw new UnauthorizedRequestError(res);
  }

  if (res.status === 403) {
    throw new ForbiddenRequestError(res);
  }

  if (res.status >= 500) {
    throw new ServerError(res)
  }
}