import { Action } from "redux";
import { ConsolidatedPatientV2 } from "../types/shared/v2/Patient";
import { StoreObject } from "../util/objects";
import { WorkspaceHLAResults } from "../types/Sample";
import { Interpretations } from "../util/hla-result";
import { AnalysisResultGroup } from "../types/shared/v2/AnalysisResultGroup";

export const EXPORT_CRISTAL_REQUEST = "EXPORT_CRISTAL_REQUEST";
export const EXPORT_CRISTAL_SUCCESS = "EXPORT_CRISTAL_SUCCESS";
export const EXPORT_CRISTAL_FAILURE = "EXPORT_CRISTAL_FAILURE";

export const EXPORT_LUMINEX_REQUEST = "EXPORT_LUMINEX_REQUEST";
export const EXPORT_LUMINEX_SUCCESS = "EXPORT_LUMINEX_SUCCESS";
export const EXPORT_LUMINEX_FAILURE = "EXPORT_LUMINEX_FAILURE";

export const TOGGLE_STATE = "TOGGLE_STATE";
export const FORCE_STATE = "FORCE_STATE";

export interface ExportAction extends Action {
  method: string;
  params: any;
}

export interface ExportCristalSuccessAction extends ExportAction {
  result: any;
}

export interface ExportLuminexSuccessAction extends ExportAction {
  result: any;
}

export interface ExportToggleStateAction extends ExportAction {
  property: string;
}

export interface ExportForceStateAction extends ExportAction {
  property: string;
  value: boolean;
}

export interface ExportCristalDataAction extends Action {
  patient: StoreObject<ConsolidatedPatientV2>;
  hlaResults: WorkspaceHLAResults;
  resultGroups: AnalysisResultGroup[];
  interpretations: Interpretations;
}

export function convertCristalDataToHprim(
  patient: StoreObject<ConsolidatedPatientV2>,
  hlaResults: WorkspaceHLAResults,
  resultGroups: AnalysisResultGroup[],
  interpretations: Interpretations
): ExportCristalDataAction {
  return {
    type: EXPORT_CRISTAL_REQUEST,
    patient,
    hlaResults,
    resultGroups,
    interpretations,
  };
}

export function exportLuminexData(patients: any): any {
  return { type: EXPORT_LUMINEX_REQUEST, patients };
}

export function toggleState(property: string): any {
  return { type: TOGGLE_STATE, property };
}

export function forceState(property: string, value: boolean): any {
  return { type: FORCE_STATE, property, value };
}
