import { Action } from "redux";

export const RUN_PIPELINE_REQUEST = 'RUN_PIPELINE_REQUEST';
export const RUN_PIPELINE_SUCCESS = 'RUN_PIPELINE_SUCCESS';
export const RUN_PIPELINE_FAILURE = 'RUN_PIPELINE_FAILURE';

export interface RunPipelineRequestAction extends Action {
    name: string
    input: any
}

export function runPipeline(name: string, input: any): RunPipelineRequestAction {
    return { type: RUN_PIPELINE_REQUEST, name, input }
}

export interface RunPipelineSuccessAction extends RunPipelineRequestAction {
    pipelineRunId: string
}

export interface RunPipelineFailureAction extends RunPipelineRequestAction {
    pipelineRunId?: string
    error: Error
}