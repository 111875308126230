import { CurrentDrawersState, drawersReducer } from './drawer'
import { CurrentCristalState, cristalReducer } from './cristal'
import { filtersReducer, FiltersState } from './filters'
import { histogramSeriesReducer, HistogramSeriesState } from './histogram'
import { sessionReducer, SessionState } from './session'
import { CurrentImportState, importReducer } from './importer'
import { uiReducer, UIState } from './ui'
import { objectsReducer, ObjectsState } from './objects'
import { transactionsReducer, TransactionsState } from './transactions'
import { notificationReducer, NotificationState } from './notification'
import { SearchState, searchReducer } from './search'
import { TenantState, tenantReducer } from './tenant'
import { AuditState, auditReducer } from './audit'
import { AuthzState, authzReducer } from './authz'
import { EventsState, eventsReducer } from './events'

export interface RootState {
  drawers: CurrentDrawersState
  cristalData: CurrentCristalState
  filters: FiltersState
  histogramSeries: HistogramSeriesState
  ui: UIState
  session: SessionState
  importState: CurrentImportState
  objects: ObjectsState
  transactions: TransactionsState
  notifications: NotificationState
  search: SearchState
  tenants: TenantState
  audit: AuditState
  authz: AuthzState
  events: EventsState
}

export default {
  drawers: drawersReducer,
  cristalData: cristalReducer,
  filters: filtersReducer,
  histogramSeries: histogramSeriesReducer,
  ui: uiReducer,
  session: sessionReducer,
  importState: importReducer,
  objects: objectsReducer,
  transactions: transactionsReducer,
  notifications: notificationReducer,
  search: searchReducer,
  tenants: tenantReducer,
  audit: auditReducer,
  authz: authzReducer,
  events: eventsReducer
}
