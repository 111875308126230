import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/root";
import rootReducer from "../reducers/root";
import { multiActionMiddleware } from "./middlewares/multi-actions";
import * as Sentry from "@sentry/react";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { Config } from "../config.ts";

const sagaMiddleware = createSagaMiddleware({
  onError: (err, info) => {
    Sentry.addBreadcrumb({
      category: "redux-saga",
      level: Sentry.Severity.Error,
      message: "Unhandled saga error",
      data: {
        sagaStack: info.sagaStack,
      },
    });

    Sentry.captureException(err);
  },
});

const rootReducerConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["session"],
};

const combinedReducer = combineReducers(rootReducer);
const persistReducerCustom = persistReducer(rootReducerConfig, combinedReducer);

const enhancers = [
  Sentry.createReduxEnhancer(),
  applyMiddleware(multiActionMiddleware),
  applyMiddleware(sagaMiddleware),
];

if (
  process.env.NODE_ENV !== "production" &&
  Config.reduxLogger &&
  Config.debug
) {
  const logger = require("redux-logger");
  enhancers.push(applyMiddleware(logger.logger));
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore(initialState = {}) {
  const store = createStore(
    persistReducerCustom,
    initialState,
    composeEnhancers(...enhancers)
  );
  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);
  return { store, persistor };
}
