import { Action } from 'redux'
import { ToggleAction, UI_TOGGLE_PROP } from '../actions/ui'

export interface UIState {
  light: boolean
  patientCardFolded: boolean
};

const defaultState = {
  light: false,
  patientCardFolded: false
}

export function uiReducer (state = defaultState, action: Action): UIState {
  switch (action.type) {
    case UI_TOGGLE_PROP:
      return handleTogglePropSuccess(state, action as ToggleAction)
  };

  return state
}

function handleTogglePropSuccess (state: UIState, action: ToggleAction): UIState {
  return {
    ...state,
    [action.prop]: !state[action.prop]
  }
}
