import { Action } from 'redux'
import { CancelExams } from '../pages/ExamDashboard/Table/ExamTable'
import { AnalysisReagentProvider, AnalysisRequest, AnalysisRequestCategory, AnalysisRequestCode, AnalysisRequestState } from '../types/shared/v2/AnalysisRequest'
import { ObjectFilter, PrivacyDomain, QueryObjectsOptions } from '../util/objects'
import { CreateObjectRequestAction, DeleteObjectRequestAction, FetchObjectRequestAction, QueryObjectsRequestAction, UpdateObjectRequestAction, createObject, deleteObject, fetchObject, queryObjects, updateObject } from "./objects"
import { AnalysisResultGroup } from '../types/shared/v2/AnalysisResultGroup'

export const ANALYSIS_REQUEST_NAMESPACE = 'analysis-request-v1'
export const CONFIRM_CANCEL_EXAM = 'confirm_cancel_exam'
export const CONFIRM_CANCEL_EXAM_SUCESS = 'confirm_cancel_exam_sucess'
export const CONFIRM_CANCEL_EXAM_FAILURE = 'confirm_cancel_exam_failure'

export function fetchPublicAnalysisRequest(objectId: string): FetchObjectRequestAction {
    return fetchObject(ANALYSIS_REQUEST_NAMESPACE, PrivacyDomain.Public, objectId)
}

export function queryPublicAnalysisRequests(filter?: ObjectFilter, options?: QueryObjectsOptions): QueryObjectsRequestAction {
    return queryObjects(ANALYSIS_REQUEST_NAMESPACE, PrivacyDomain.Public, filter, options)
}

export function queryPendingAnalysisRequests(): QueryObjectsRequestAction {
    return queryObjects(ANALYSIS_REQUEST_NAMESPACE, PrivacyDomain.Public, { eq: { 'types.*': AnalysisRequestState.TakenIntoAccount } }, { hydrate: true })
}

export function queryPatientAnalysisRequests(inlogId: string): QueryObjectsRequestAction {
    return queryObjects(ANALYSIS_REQUEST_NAMESPACE, PrivacyDomain.Public, { eq: { 'ref.patient.inlogId': inlogId } }, { hydrate: true })
}

export function updatePublicAnalysisRequest(objectId: string, analysisRequest: AnalysisRequest): UpdateObjectRequestAction {
    return updateObject(ANALYSIS_REQUEST_NAMESPACE, PrivacyDomain.Public, objectId, analysisRequest)
}

export interface ConfirmCancelExamAction extends Action {
    cancelExams: CancelExams
}

export function confirmCancelExam(cancelExams: CancelExams): ConfirmCancelExamAction {
    return { type: CONFIRM_CANCEL_EXAM, cancelExams }
}

export const ANALYSIS_REQUEST_CODE_NAMESPACE = 'analysis-request-code-v1'

export function fetchPublicAnalysisRequestCode(objectId: string): FetchObjectRequestAction {
    return fetchObject(ANALYSIS_REQUEST_CODE_NAMESPACE, PrivacyDomain.Public, objectId)
}

export function queryPublicAnalysisRequestCodes(filter?: ObjectFilter, options?: QueryObjectsOptions): QueryObjectsRequestAction {
    return queryObjects(ANALYSIS_REQUEST_CODE_NAMESPACE, PrivacyDomain.Public, filter, options)
}

export function updatePublicAnalysisRequestCode(objectId: string, data: Partial<AnalysisRequestCode>, partial?: boolean): UpdateObjectRequestAction {
    return updateObject(ANALYSIS_REQUEST_CODE_NAMESPACE, PrivacyDomain.Public, objectId, data, partial)
}

export function createPublicAnalysisRequestCode(data: AnalysisRequestCode): CreateObjectRequestAction {
    return createObject(ANALYSIS_REQUEST_CODE_NAMESPACE, PrivacyDomain.Public, data)
}

export function deletePublicAnalysisRequestCode(objectId: string): DeleteObjectRequestAction {
    return deleteObject(ANALYSIS_REQUEST_CODE_NAMESPACE, PrivacyDomain.Public, objectId)
}

export const ANALYSIS_REAGENT_PROVIDER_NAMESPACE = 'analysis-reagent-provider-v1'

export function fetchPublicAnalysisReagentProvider(objectId: string): FetchObjectRequestAction {
    return fetchObject(ANALYSIS_REAGENT_PROVIDER_NAMESPACE, PrivacyDomain.Public, objectId)
}

export function queryPublicAnalysisReagentProviders(filter?: ObjectFilter, options?: QueryObjectsOptions): QueryObjectsRequestAction {
    return queryObjects(ANALYSIS_REAGENT_PROVIDER_NAMESPACE, PrivacyDomain.Public, filter, options)
}

export function updatePublicAnalysisReagentProvider(objectId: string, data: Partial<AnalysisReagentProvider>, partial?: boolean): UpdateObjectRequestAction {
    return updateObject(ANALYSIS_REAGENT_PROVIDER_NAMESPACE, PrivacyDomain.Public, objectId, data, partial)
}

export function createPublicAnalysisReagentProvider(data: AnalysisReagentProvider): CreateObjectRequestAction {
    return createObject(ANALYSIS_REAGENT_PROVIDER_NAMESPACE, PrivacyDomain.Public, data)
}

export function deletePublicAnalysisReagentProvider(objectId: string): DeleteObjectRequestAction {
    return deleteObject(ANALYSIS_REAGENT_PROVIDER_NAMESPACE, PrivacyDomain.Public, objectId)
}

export const ANALYSIS_REQUEST_CATEGORY_NAMESPACE = 'analysis-request-category-v1'

export function fetchPublicAnalysisRequestCategory(objectId: string): FetchObjectRequestAction {
    return fetchObject(ANALYSIS_REQUEST_CATEGORY_NAMESPACE, PrivacyDomain.Public, objectId)
}

export function queryPublicAnalysisRequestCategories(filter?: ObjectFilter, options?: QueryObjectsOptions): QueryObjectsRequestAction {
    return queryObjects(ANALYSIS_REQUEST_CATEGORY_NAMESPACE, PrivacyDomain.Public, filter, options)
}

export function updatePublicAnalysisCategory(objectId: string, data: Partial<AnalysisRequestCategory>, partial?: boolean): UpdateObjectRequestAction {
    return updateObject(ANALYSIS_REQUEST_CATEGORY_NAMESPACE, PrivacyDomain.Public, objectId, data, partial)
}

export function createPublicAnalysisCategory(data: AnalysisRequestCategory): CreateObjectRequestAction {
    return createObject(ANALYSIS_REQUEST_CATEGORY_NAMESPACE, PrivacyDomain.Public, data)
}

export function deletePublicAnalysisCategory(objectId: string): DeleteObjectRequestAction {
    return deleteObject(ANALYSIS_REQUEST_CATEGORY_NAMESPACE, PrivacyDomain.Public, objectId)
}

export const ANALYSIS_RESULT_GROUP_NAMESPACE = 'analysis-result-group-v1'

export function fetchPublicAnalysisResultGroup(objectId: string): FetchObjectRequestAction {
    return fetchObject(ANALYSIS_RESULT_GROUP_NAMESPACE, PrivacyDomain.Public, objectId)
}

export function queryPublicAnalysisResultGroups(filter?: ObjectFilter, options?: QueryObjectsOptions): QueryObjectsRequestAction {
    return queryObjects(ANALYSIS_RESULT_GROUP_NAMESPACE, PrivacyDomain.Public, filter, options)
}

export function updatePublicAnalysisResultGroup(objectId: string, data: Partial<AnalysisResultGroup>, partial?: boolean): UpdateObjectRequestAction {
    return updateObject(ANALYSIS_RESULT_GROUP_NAMESPACE, PrivacyDomain.Public, objectId, data, partial)
}

export function createPublicAnalysisResultGroup(data: AnalysisResultGroup): CreateObjectRequestAction {
    return createObject(ANALYSIS_RESULT_GROUP_NAMESPACE, PrivacyDomain.Public, data)
}

export function deletePublicAnalysisResultGroup(objectId: string): DeleteObjectRequestAction {
    return deleteObject(ANALYSIS_RESULT_GROUP_NAMESPACE, PrivacyDomain.Public, objectId)
}
