import React, { FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, RouteProps } from 'react-router'
import { selectIsLoggedIn } from '../../selectors/session'
import { isNullish } from '../../util/data'

export interface PrivateRouteProps extends RouteProps {

}

export const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({ component, ...rest }) => {
  const isLoggedIn: boolean = useSelector(selectIsLoggedIn)

  useEffect(() => {
    sessionStorage.setItem('lastPath', !isNullish(rest.location) && rest.location !== undefined ? `${rest.location.pathname}${rest.location.search}` : '')
  }, [rest.location])

  const Comp = component as FunctionComponent<any>

  return (
    <Route
      {...rest}
      render={(props) => isLoggedIn
        ? <Comp {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}
