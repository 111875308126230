import { Action } from 'redux'

export interface Tenant {
  Name: string
  Title: string
}
export interface TenantSubject extends Tenant {
  Subjects: string[]
}

export interface TenantAction extends Action {
  tenant: string
}

export const TENANTS_DATA_REQUEST = 'TENANTS_DATA_REQUEST'
export const TENANTS_DATA_REQUEST_SUCCESS = 'TENANTS_DATA_REQUEST_SUCCESS'
export const TENANTS_DATA_REQUEST_FAILURE = 'TENANTS_DATA_REQUEST_FAILURE'

export interface TenantsAction extends Action {
  method: string
  params: string
}

export interface TenantsRequestDataSuccessAction extends TenantsAction {
  result: TenantsResult
}

export interface TenantRequestDataSuccessAction extends TenantsAction {
  result: TenantResult
}

export interface TenantsResult {
  Data: {
    Tenants: Tenant[]
  }
}

export interface TenantResult {
  Data: {
    Tenant: TenantSubject
  }
}

export function getTenantsData (): any {
  return { type: TENANTS_DATA_REQUEST }
}

export const TENANT_DATA_REQUEST = 'TENANT_DATA_REQUEST'
export const TENANT_DATA_REQUEST_SUCCESS = 'TENANT_DATA_REQUEST_SUCCESS'
export const TENANT_DATA_REQUEST_FAILURE = 'TENANT_DATA_REQUEST_FAILURE'

export function getTenantData (tenant: string): any {
  return { type: TENANT_DATA_REQUEST, tenant }
}

export interface GetRoleAction extends Action {
  tenant: string
  subject: string
}

export const GET_ROLE_REQUEST = 'GET_ROLE_REQUEST'
export const GET_ROLE_REQUEST_SUCCESS = 'GET_ROLE_REQUEST_SUCCESS'
export const GET_ROLE_REQUEST_FAILURE = 'GET_ROLE_REQUEST_FAILURE'

export interface GetRoleSuccessAction extends TenantsAction {
  result: RoleResult
  subject: string
}

export interface RoleResult {
  Data: {
    Roles: string[]
  }
}

export function getRole (tenant: string, subject: string): GetRoleAction {
  return { type: GET_ROLE_REQUEST, tenant, subject }
}

export const ADD_ROLE_REQUEST = 'ADD_ROLE_REQUEST'
export const ADD_ROLE_REQUEST_SUCCESS = 'ADD_ROLE_REQUEST_SUCCESS'
export const ADD_ROLE_REQUEST_FAILURE = 'ADD_ROLE_REQUEST_FAILURE'

export interface AddRoleAction extends Action {
  tenant: string
  subject: string
  role: string
}

export function addRole (tenant: string, subject: string, role: string): AddRoleAction {
  return { type: ADD_ROLE_REQUEST, tenant, role, subject }
}

export const REMOVE_ROLE_REQUEST = 'REMOVE_ROLE_REQUEST'
export const REMOVE_ROLE_REQUEST_SUCCESS = 'REMOVE_ROLE_REQUEST_SUCCESS'
export const REMOVE_ROLE_REQUEST_FAILURE = 'REMOVE_ROLE_REQUEST_FAILURE'

export interface RemoveRoleAction extends Action {
  tenant: string
  subject: string
  role: string
}
export function removeRole (tenant: string, subject: string, role: string): RemoveRoleAction {
  return { type: REMOVE_ROLE_REQUEST, tenant, role, subject }
}

export const SWITCH_TENANT_REQUEST = 'SWITCH_TENANT_REQUEST'
export const SWITCH_TENANT_REQUEST_SUCCESS = 'SWITCH_TENANT_REQUEST_SUCCESS'
export const SWITCH_TENANT_REQUEST_FAILURE = 'SWITCH_TENANT_REQUEST_FAILURE'

export interface SwitchTenantAction extends Action {
  tenant: string
}
export function switchTenant (tenant: string): SwitchTenantAction {
  return { type: SWITCH_TENANT_REQUEST, tenant }
}
