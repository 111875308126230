import { all, call, put, takeEvery, select } from 'redux-saga/effects'
import { CHECK_AUTHZ_REQUEST, CHECK_AUTHZ_REQUEST_FAILURE, CHECK_AUTHZ_REQUEST_SUCCESS, CheckAuthzAction } from '../actions/authz'
import { Config } from '../config'
import { fetchWithCredentials } from '../util/fetch'
import { InvalidRequestError } from '../util/error'
import { selectIdToken } from '../selectors/session'
import { handleUnauthorizedRequestError } from './helper'
import { AuthzAction, AuthzResource } from '../util/authz'
import { forwardTx } from '../hooks/useTransaction'
import { Action } from 'redux'

export function* authzSaga(): Generator<any, any, any> {
  yield all([
    takeEvery(CHECK_AUTHZ_REQUEST, checkAuthzSaga)
  ])
}

export function* checkAuthzSaga(action: CheckAuthzAction): any {
  const authzAction = action.authzAction
  const authzResource = action.authzResource
  const idToken = yield select(selectIdToken)
  let result
  try {
    result = yield call(checkRole, idToken, authzAction, authzResource)
  } catch (err) {
    const handled: boolean = yield call(handleUnauthorizedRequestError, err, action)
    if (handled) return

    yield put(forwardTx(action, { type: CHECK_AUTHZ_REQUEST_FAILURE, err } as Action))
    return
  }
  yield put(forwardTx(action, { type: CHECK_AUTHZ_REQUEST_SUCCESS, result } as Action))
}

const checkRole = async (idToken: string, authzAction: AuthzAction, authzResource: AuthzResource) => {
  const url = `${Config.authzServerURL}/api/v1/check`
  const res = await fetchWithCredentials(idToken, url, {
    method: 'POST',
    body: JSON.stringify({
      action: authzAction,
      resource: authzResource
    })
  })
  if (res.status === 400) {
    throw new InvalidRequestError(res)
  }

  const result = await res.json()
  return { allowed: result.Data.Result, authzAction: authzAction, authzResource: authzResource }
}
