

export function poll<T>(pollFn: () => Promise<T>, checkFn: (v: T) => boolean, interval: number, maxAttempts: number): Promise<T> {
    let attempts = 0

    const cb = (resolve, reject) => {
        attempts++
        pollFn()
            .then(value => {
                const ok = checkFn(value)
                if (ok) {
                    return resolve(value)
                }

                if (attempts < maxAttempts) {
                    setTimeout(cb, interval, resolve, reject)
                    return
                } else {
                    return reject(new Error("Poll timed out !"))
                }
            })
            .catch(reject)
    }

    return new Promise(cb)
}