import { getConfigDefaults, getAppInfo } from './macro' with { type: 'macro' };

(window as any).__CONFIG__ = {
    ...getConfigDefaults(),
    ...((window as any).__CONFIG__ || {})
};

(window as any).__APP__ = {
    ...getAppInfo(),
    ...((window as any).__APP__ || {})
};