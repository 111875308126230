import { AuditLog } from '../types/Audit'
import { InvalidRequestError, ServerError } from './error'
import { fetchWithCredentials } from './fetch'
import { ObjectFilter, QueryObjectsOptions } from './objects'

interface LogsResult {
  Data: {
    Decisions: AuditLog[]
  }
  Total: number
}

export class AuditServerClient {
  baseUrl: string
  idToken: string

  constructor(baseUrl: string, idToken: string) {
    this.baseUrl = baseUrl
    this.idToken = idToken

    this.queryLogs = this.queryLogs.bind(this)
  }

  async queryLogs(options?: QueryObjectsOptions, filter?: ObjectFilter): Promise<LogsResult> {
    let queryString: string[] = []
    if (options?.offset !== undefined) {
      queryString.push(`offset=${options.offset}`)
    }
    if (options?.limit !== undefined) {
      queryString.push(`limit=${options.limit}`)
    }

    if (filter !== undefined) {
      queryString.push(`filter=${encodeURIComponent(JSON.stringify(filter))}`)
    }

    const url = `${this.baseUrl}/api/v1/audit?${queryString.join('&')}`
    const res = await fetchWithCredentials(this.idToken, url, {
      method: 'GET'
    })

    if (res.status === 400) {
      throw new InvalidRequestError(res)
    }

    if (res.status >= 500) {
      throw new ServerError(res)
    }

    const result = await res.json()
    return result
  }
}
