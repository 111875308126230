/** 
 * !!! DO NOT MODIFY !!!
 * 
 * File generated from types definition in project https://forge.cadoles.com/EFS/sheila.
 *
 * See misc/script/generate-type-defs.sh script for more informations.
 **/
"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.classesLabels = exports.ClassName = void 0;
var ClassName;
(function (ClassName) {
    ClassName["CLASS1"] = "class1";
    ClassName["CLASS2"] = "class2";
})(ClassName || (exports.ClassName = ClassName = {}));
exports.classesLabels = (_a = {},
    _a[ClassName.CLASS1] = "Bilan Classe I",
    _a[ClassName.CLASS2] = "Bilan Classe 2",
    _a);
