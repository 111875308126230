import { Action } from 'redux'
import { NotificationBase } from '../types/Notification'

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS'
export const ADD_NOTIFICATION_FAILURE = 'ADD_NOTIFICATION_FAILURE'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

export interface AddNotificationAction extends Action {
  notification: NotificationBase
}

export function addNotification (notification: NotificationBase): AddNotificationAction {
  return {
    type: ADD_NOTIFICATION,
    notification: notification
  }
};

export interface RemoveNotificationAction extends Action {
  notificationId: number
}

export const removeNotification = (notificationId: number): RemoveNotificationAction => ({
  type: REMOVE_NOTIFICATION,
  notificationId: notificationId
})
