import { Action } from 'redux'
import {
  TOGGLE_STATE,
  FORCE_STATE,
  ImportToggleStateAction,
  ImportForceStateAction
} from '../actions/importer'

export interface CurrentImportState {
  uploading: boolean
  converting: boolean
  parsing: boolean
  imported: boolean
  aborted: boolean
};

const defaultState = {
  uploading: false,
  converting: false,
  parsing: false,
  imported: false,
  aborted: false
}

export function importReducer (state = defaultState, action: Action) {
  switch (action.type) {
    case TOGGLE_STATE:
      return handleToggleState(state, action as ImportToggleStateAction)
    case FORCE_STATE:
      return handleForceState(state, action as ImportForceStateAction)
  };

  return state
}

function handleToggleState (state: CurrentImportState, action: ImportToggleStateAction) {
  return {
    ...state,
    [action.property]: !state[action.property as 'uploading'|'converting'|'parsing'|'imported'|'aborted']
  }
}

function handleForceState (state: CurrentImportState, action: ImportForceStateAction) {
  return {
    ...state,
    [action.property]: action.value
  }
}
