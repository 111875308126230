export interface Pipeline {
    ID: string
    Metadata: {
        Name: string
        Type: string
        [key: string]: any
    }
}

export enum PipelineRunState {
    Created = 0,
    Running = 1,
    Error = 2,
    Success = 3,
    Interrupted = 4
}

const PipelineRunStateLabels = {
    [PipelineRunState.Created]: "Created",
    [PipelineRunState.Running]: "Running",
    [PipelineRunState.Error]: "Error",
    [PipelineRunState.Success]: "Success",
    [PipelineRunState.Interrupted]: "Interrupted",
}

export function getPipelineRuneStateLabel(state: PipelineRunState): string {
    return PipelineRunStateLabels[state] ?? `Unknown (${state})`
}

export interface PipelineRun {
    CreatedAt: string
    ID: string
    Input: {
        ID: string
        Data: any
    }
    Outputs: any[]
    PipelineID: string
    State: PipelineRunState
    Tenant: string
    UpdatedAt: string
}