import moment from 'moment'
import { isNullish } from './data'

export function asDate (d: Date|string): Date {
  if (typeof d === 'string') return new Date(d)
  if (d instanceof Date) return d

  console.warn(`Unexpected date value '${JSON.stringify(d)}' !`)

  return new Date(0)
}

const intl = Intl.DateTimeFormat(navigator.language, {
  weekday: 'long',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
})

export function formatDate (d: Date|string): string {
  d = asDate(d)
  return intl.format(d)
}

export function inlogToISODate (d: string|undefined): Date {
  if (isNullish(d)) {
    return new Date()
  }

  const year = d?.substr(0, 4)
  const month = d?.substr(4, 2)
  const day = d?.substr(6, 2)
  const hours = d?.substr(8, 2)
  const minutes = d?.substr(10, 2)
  const seconds = d?.substr(12, 2)

  const iso = String(year) + '-' + String(month) + '-' + String(day) + 'T' + String(hours) + ':' + String(minutes) + ':' + String(seconds)

  return new Date(iso)
}

export function ISOtoInlogDate (d: Date|string): string {
  const myDate = typeof (d) === 'string' ? new Date(d) : d
  return String(myDate.getFullYear()) + '0' + String(myDate.getMonth() + 1).slice(-2) + '0' + String(myDate.getDate()).slice(-2) + '0' + String(myDate.getHours()) + '0' + String(myDate.getMinutes()) + '00'
}
