import { Action } from 'redux'

export const IMPORT_INLOG_REQUEST = 'IMPORT_INLOG_REQUEST'
export const IMPORT_INLOG_SUCCESS = 'IMPORT_INLOG_SUCCESS'
export const IMPORT_INLOG_FAILURE = 'IMPORT_INLOG_FAILURE'

export const SYNCHRO_FUSION_REQUEST = 'SYNCHRO_FUSION_REQUEST'
export const SYNCHRO_FUSION_SUCCESS = 'SYNCHRO_FUSION_SUCCESS'
export const SYNCHRO_FUSION_FAILURE = 'SYNCHRO_FUSION_FAILURE'

export const TOGGLE_STATE = 'TOGGLE_STATE'
export const FORCE_STATE = 'FORCE_STATE'

export interface ImportInlogAction extends Action {
  method: string
  params: any
}

export interface ImportInlogSuccessAction extends ImportInlogAction {
  result: any
}

export interface SynchroFusionSuccessAction extends ImportInlogAction {
  result: any
}

export interface ImportToggleStateAction extends ImportInlogAction {
  property: string
}

export interface ImportForceStateAction extends ImportInlogAction {
  property: string
  value: boolean
}

export function getInlogDataFromFile (file: any) {
  return { type: IMPORT_INLOG_REQUEST, file }
}

export function synchroFusion (currentPatient: string, patientData: any) {
  return { type: SYNCHRO_FUSION_REQUEST, currentPatient, patientData }
}

export function toggleState (property: string) {
  return { type: TOGGLE_STATE, property }
}

export function forceState (property: string, value: boolean) {
  return { type: FORCE_STATE, property, value }
}
