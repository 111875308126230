import { Action } from 'redux'
import { AuditLog } from '../types/Audit'
import { ObjectFilter, QueryObjectsOptions } from '../util/objects'

export const AUDIT_REQUEST = 'AUDIT_REQUEST'
export const AUDIT_REQUEST_SUCCESS = 'AUDIT_REQUEST_SUCCESS'
export const AUDIT_REQUEST_FAILURE = 'AUDIT_REQUEST_FAILURE'

export interface GetAuditLogAction extends Action {
  options?: QueryObjectsOptions
  filter?: ObjectFilter
}
export interface GetAuditLogSuccessAction extends Action {
  result: any
}

export interface AuditLogResult {
  Decisions: AuditLog[]
  Total: number
}

export function getAuditLogs(options?: QueryObjectsOptions, filter?: ObjectFilter): GetAuditLogAction {
  return {
    type: AUDIT_REQUEST,
    options,
    filter
  }
};
