import { all, put, takeEvery, select } from "redux-saga/effects";
import {
  CONFIRM_CANCEL_EXAM,
  CONFIRM_CANCEL_EXAM_FAILURE,
  CONFIRM_CANCEL_EXAM_SUCESS,
  ConfirmCancelExamAction,
  updatePublicAnalysisRequest,
} from "../actions/analysis-request";
import { selectAnalysisRequestIndexedObjects } from "../selectors/analysis-request";
import { addNotification } from "../actions/notification";
import { NOTIFICATION_STATUS_SUCCESS } from "../types/Notification";

export function* analysisRequestSaga(): Generator<any, any, any> {
  yield all([takeEvery(CONFIRM_CANCEL_EXAM, cancelExamsSaga)]);
}

export function* cancelExamsSaga(
  action: ConfirmCancelExamAction
): Generator<any, any, any> {
  try {
    const analysisRequestObjects = yield select(selectAnalysisRequestIndexedObjects);
    const cancelExams = action.cancelExams;
    const cancelExamKeys = Object.keys(cancelExams);
    for (const cancelExamKey of cancelExamKeys) {
      const typesToDelete = cancelExams[cancelExamKey].type;
      for (const type of typesToDelete) {
        delete analysisRequestObjects[cancelExamKey].data.types[type];
        yield put(
          updatePublicAnalysisRequest(
            analysisRequestObjects[cancelExamKey].id,
            analysisRequestObjects[cancelExamKey].data
          )
        );
        yield put(
          addNotification({
            message: `Examen ${type} pour le numéro de prélèvement ${analysisRequestObjects[cancelExamKey].data.requestorSampleId} supprimés.`,
            status: NOTIFICATION_STATUS_SUCCESS,
          })
        );
      }
    }
  } catch (err) {
    yield put({ type: CONFIRM_CANCEL_EXAM_FAILURE, err });
    return;
  }
  yield put({ type: CONFIRM_CANCEL_EXAM_SUCESS });
}
