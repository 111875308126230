import { Action } from "redux";
import {
  CREATE_OBJECT_SUCCESS,
  FETCH_OBJECT_SUCCESS,
  QueryObjectsSuccessAction,
  QUERY_OBJECTS_SUCCESS,
  ObjectOpSuccessAction,
  UPDATE_OBJECT_SUCCESS,
  DELETE_OBJECT_SUCCESS,
  ObjectDeleteSuccessAction,
} from "../actions/objects";
import {
  ObjectHeader,
  PrivacyDomain,
  StoreObject,
} from "../util/objects";
import { PATIENT_NAMESPACE } from "../actions/patient";
import { ANALYSIS_REAGENT_PROVIDER_NAMESPACE, ANALYSIS_REQUEST_CATEGORY_NAMESPACE, ANALYSIS_REQUEST_CODE_NAMESPACE, ANALYSIS_RESULT_GROUP_NAMESPACE } from "../actions/analysis-request";

// Modify this to automatically create indexes
// for the different object collections.
const indexes: Indexes = {
  byNamespace: {
    [PATIENT_NAMESPACE]: {
      byDomain: {
        [PrivacyDomain.Public]: ["inlogId"],
      },
    },
    [ANALYSIS_REAGENT_PROVIDER_NAMESPACE]: {
      byDomain: {
        [PrivacyDomain.Public]: ["code"],
      },
    },
    [ANALYSIS_REQUEST_CODE_NAMESPACE]: {
      byDomain: {
        [PrivacyDomain.Public]: ["code"],
      },
    },
    [ANALYSIS_RESULT_GROUP_NAMESPACE]: {
      byDomain: {
        [PrivacyDomain.Public]: ["code"],
      },
    },
    [ANALYSIS_REQUEST_CATEGORY_NAMESPACE]: {
      byDomain: {
        [PrivacyDomain.Public]: ["code"],
      },
    },
  },
};

export interface ObjectsState {
  byNamespace: {
    [namespace: string]: {
      byDomain: {
        [domain in PrivacyDomain]?: {
          byId: {
            [id: string]: ObjectHeader | StoreObject;
          };
          byAttr: {
            [name: string]: {
              [value: string]: string;
            };
          };
        };
      };
    };
  };
}

const defaultState: ObjectsState = {
  byNamespace: {},
};

export const objectsReducer = (
  state = defaultState,
  action: Action
): ObjectsState => {
  switch (action.type) {
    case FETCH_OBJECT_SUCCESS:
      return updateIndexes(
        handleSingleObjectSuccess(state, action as ObjectOpSuccessAction),
        action as ObjectOpSuccessAction
      );
    case CREATE_OBJECT_SUCCESS:
      return updateIndexes(
        handleSingleObjectSuccess(state, action as ObjectOpSuccessAction),
        action as ObjectOpSuccessAction
      );
    case UPDATE_OBJECT_SUCCESS:
      return updateIndexes(
        handleSingleObjectSuccess(state, action as ObjectOpSuccessAction),
        action as ObjectOpSuccessAction
      );
    case QUERY_OBJECTS_SUCCESS:
      return updateIndexes(
        handleQueryObjectsSuccess(state, action as QueryObjectsSuccessAction),
        action as QueryObjectsSuccessAction
      );
    case DELETE_OBJECT_SUCCESS:
      return updateIndexes(
        handleDeleteObjectSuccess(state, action as ObjectDeleteSuccessAction),
        action as ObjectOpSuccessAction
      );
  }

  return state;
};

function handleSingleObjectSuccess(
  state: ObjectsState,
  action: ObjectOpSuccessAction
): ObjectsState {
  return {
    ...state,
    byNamespace: {
      ...state.byNamespace,
      [action.namespace]: {
        ...state.byNamespace[action.namespace],
        byDomain: {
          ...state.byNamespace[action.namespace]?.byDomain,
          [action.privacyDomain]: {
            ...state.byNamespace[action.namespace]?.byDomain[
            action.privacyDomain
            ],
            byId: {
              ...state.byNamespace[action.namespace]?.byDomain[
                action.privacyDomain
              ]?.byId,
              [action.object.id]: action.object,
            },
            byAttr: {
              ...state.byNamespace[action.namespace]?.byDomain[
                action.privacyDomain
              ]?.byAttr,
            },
          },
        },
      },
    },
  };
}

function handleDeleteObjectSuccess(
  state: ObjectsState,
  action: ObjectDeleteSuccessAction
): ObjectsState {
  const newState = {
    ...state,
    byNamespace: {
      ...state.byNamespace,
      [action.namespace]: {
        ...state.byNamespace[action.namespace],
        byDomain: {
          ...state.byNamespace[action.namespace]?.byDomain,
          [action.privacyDomain]: {
            ...state.byNamespace[action.namespace]?.byDomain[
            action.privacyDomain
            ],
            byId: {
              ...state.byNamespace[action.namespace]?.byDomain[
                action.privacyDomain
              ]?.byId,
            },
          },
        },
      },
    },
  };
  delete newState.byNamespace[action.namespace]?.byDomain[action.privacyDomain]?.byId[action.id]
  return newState
}

function handleQueryObjectsSuccess(
  state: ObjectsState,
  action: QueryObjectsSuccessAction
): ObjectsState {
  return {
    ...state,
    byNamespace: {
      ...state.byNamespace,
      [action.namespace]: {
        ...state.byNamespace[action.namespace],
        byDomain: {
          ...state.byNamespace[action.namespace]?.byDomain,
          [action.privacyDomain]: {
            ...state.byNamespace[action.namespace]?.byDomain[
            action.privacyDomain
            ],
            byId: action.objects.reduce(
              (byId, head) => {
                let obj = {
                  ...(byId[head.id] ?? {}),
                  ...head,
                };
                byId[obj.id] = {
                  ...(byId[obj.id] || {}),
                  ...obj,
                };
                return byId;
              },
              {
                ...state.byNamespace[action.namespace]?.byDomain[
                  action.privacyDomain
                ]?.byId,
              }
            ),
            byAttr: {
              ...state.byNamespace[action.namespace]?.byDomain[
                action.privacyDomain
              ]?.byAttr,
            },
          },
        },
      },
    },
  };
}

interface Indexes {
  byNamespace: {
    [namespace: string]: {
      byDomain: {
        [domain in PrivacyDomain]?: string[];
      };
    };
  };
}

function updateIndexes(
  state: ObjectsState,
  action: ObjectOpSuccessAction | QueryObjectsSuccessAction | ObjectDeleteSuccessAction
): ObjectsState {
  const toAdd: StoreObject[] = [];
  const toDelete: { namespace: string, id: string, privacyDomain: PrivacyDomain }[] = [];

  if ("object" in action && "data" in action.object) {
    toAdd.push(action.object);
  } else if ("objects" in action) {
    action.objects.forEach((obj) => {
      if (!("data" in obj)) return;
      toAdd.push(obj);
    });
  } else if ("id" in action && "namespace" in action && "privacyDomain" in action) {
    toDelete.push({
      namespace: action.namespace,
      privacyDomain: action.privacyDomain,
      id: action.id
    });
  }

  toDelete.forEach(obj => {
    const byAttr = state.byNamespace[obj.namespace]?.byDomain[obj.privacyDomain]?.byAttr ?? {};
    Object.keys(byAttr).forEach(attr => {
      Object.keys(byAttr[attr]).forEach(key => {
        if (byAttr[attr][key] !== obj.id) return
        delete byAttr[attr][key]
      })
    })
  })

  const attributes =
    indexes.byNamespace[action.namespace]?.byDomain[action.privacyDomain];
  if (!attributes) return state;

  attributes.forEach((name) => {
    state = {
      ...state,
      byNamespace: {
        ...state.byNamespace,
        [action.namespace]: {
          ...state.byNamespace[action.namespace],
          byDomain: {
            ...state.byNamespace[action.namespace]?.byDomain,
            [action.privacyDomain]: {
              ...state.byNamespace[action.namespace]?.byDomain[
              action.privacyDomain
              ],
              byAttr: {
                ...state.byNamespace[action.namespace]?.byDomain[
                  action.privacyDomain
                ]?.byAttr,
                [name]: {
                  ...(state.byNamespace[action.namespace]?.byDomain[
                    action.privacyDomain
                  ]?.byAttr || {})[name],
                },
              },
            },
          },
        },
      },
    };

    toAdd.forEach((obj) => {
      const value = obj.data[name];
      if (value === undefined) return;

      state = {
        ...state,
        byNamespace: {
          ...state.byNamespace,
          [action.namespace]: {
            ...state.byNamespace[action.namespace],
            byDomain: {
              ...state.byNamespace[action.namespace]?.byDomain,
              [action.privacyDomain]: {
                ...state.byNamespace[action.namespace]?.byDomain[
                action.privacyDomain
                ],
                byAttr: {
                  ...state.byNamespace[action.namespace]?.byDomain[
                    action.privacyDomain
                  ]?.byAttr,
                  [name]: {
                    ...(state.byNamespace[action.namespace]?.byDomain[
                      action.privacyDomain
                    ]?.byAttr || {})[name],
                    [value]: obj.id,
                  },
                },
              },
            },
          },
        },
      };
    });
  });



  return state;
}
