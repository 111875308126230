import { AnalysisRequest, AnalysisRequestRef, AnalysisRequestState } from '../types/shared/v2/AnalysisRequest';
import { AnalysisResultGroup, AnalysisResultGroupThresholdOperator, AnalysisResultGroupThresholds } from '../types/shared/v2/AnalysisResultGroup';
import { StoreObject } from './objects';

export function hasPendingRequests(analysisRequests: AnalysisRequest[]): boolean {
    return analysisRequests.some(ar => Object.values(ar.types).some(s => s === AnalysisRequestState.TakenIntoAccount))
}

const thresholdFuncs: {
    [op in AnalysisResultGroupThresholdOperator]: (
        v: number,
        t: number
    ) => boolean;
} = {
    [AnalysisResultGroupThresholdOperator.GT]: function (
        v: number,
        t: number
    ): boolean {
        return v > t;
    },
    [AnalysisResultGroupThresholdOperator.GTE]: function (
        v: number,
        t: number
    ): boolean {
        return v >= t;
    },
    [AnalysisResultGroupThresholdOperator.LT]: function (
        v: number,
        t: number
    ): boolean {
        return v < t;
    },
    [AnalysisResultGroupThresholdOperator.LTE]: function (
        v: number,
        t: number
    ): boolean {
        return v <= t;
    },
    [AnalysisResultGroupThresholdOperator.EQ]: function (
        v: number,
        t: number
    ): boolean {
        return v === t;
    },
};

export function matchThresholds(
    thresholds: AnalysisResultGroupThresholds,
    value: number
): boolean {
    const operators = Object.keys(thresholds);

    if (operators.length === 0) return true;

    for (let i = 0; i < operators.length; i++) {
        const op = operators[i];
        if (thresholdFuncs[op] === undefined) {
            throw new Error(
                `Could not find threshold function associated to operator '${op}' !`
            );
        }

        const match = thresholdFuncs[op](value, thresholds[op]);

        if (!match) return false;
    }

    return true;
}

// Retourne le groupe de résultat le plus spécifiquement proche de la valeur données
// i.e. qui encadre de manière la plus précise celle ci.
export function findBestMatchingGroup(groups: StoreObject<AnalysisResultGroup>[], value: number): StoreObject<AnalysisResultGroup> | undefined {
    const matches = groups.filter(group => matchThresholds(group.data.thresholds, value));

    // On recherche le groupe le plus spécifique i.e.
    // celui avec les plus de seuils définis
    matches.sort((g1: StoreObject<AnalysisResultGroup>, g2: StoreObject<AnalysisResultGroup>) => {
        const t1 = getThresholdRange(g1.data.thresholds)
        const t2 = getThresholdRange(g2.data.thresholds)
        if (t1 > t2) return 1;
        if (t1 < t2) return -1;
        if (g1.data.weight > g2.data.weight) return -1;
        if (g1.data.weight < g2.data.weight) return 1;
        return 0;
    })

    return matches[0];
}

export function getThresholdRange(thresholds: AnalysisResultGroupThresholds): number {
    if (thresholds[AnalysisResultGroupThresholdOperator.EQ]) return 0;
    const min = thresholds[AnalysisResultGroupThresholdOperator.GT] || thresholds[AnalysisResultGroupThresholdOperator.GTE] || Number.MIN_SAFE_INTEGER;
    const max = thresholds[AnalysisResultGroupThresholdOperator.LT] || thresholds[AnalysisResultGroupThresholdOperator.LTE] || Number.MAX_SAFE_INTEGER;
    return max - min;
}

export function matchRef(analysisRequest: AnalysisRequest, ref: AnalysisRequestRef): boolean {
    return analysisRequest.executorSampleId == ref.executorSampleId &&
        analysisRequest.requestorSampleId === ref.requestorSampleId &&
        analysisRequest.requestDate === ref.requestDate &&
        analysisRequest.samplingEndDate === ref.samplingEndDate &&
        analysisRequest.samplingStartDate === ref.samplingStartDate
}

export function areRefEquals(ref1: AnalysisRequestRef, ref2: AnalysisRequestRef): boolean {
    return ref1.executorSampleId == ref2.executorSampleId &&
        ref1.requestorSampleId === ref2.requestorSampleId &&
        ref1.requestDate === ref2.requestDate &&
        ref1.samplingEndDate === ref2.samplingEndDate &&
        ref1.samplingStartDate === ref2.samplingStartDate
}