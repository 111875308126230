import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects'
import { EXPORT_WORKLIST_FAILURE, EXPORT_WORKLIST_REQUEST, EXPORT_WORKLIST_SUCCESS, ExportWorkListRequestAction, updatePublicWorklist } from '../actions/worklist';
import { forwardTx } from '../hooks/useTransaction';
import { RUN_PIPELINE_FAILURE, RUN_PIPELINE_SUCCESS, RunPipelineFailureAction, RunPipelineSuccessAction, runPipeline } from '../actions/pipeline'
import { selectIdToken } from '../selectors/session';
import { FileServerClient, Filesystem } from '../util/filesystems';
import { Config } from '../config';
import { UPDATE_OBJECT_FAILURE, UPDATE_OBJECT_SUCCESS } from '../actions/objects';
import { addNotification } from '../actions/notification';
import { NOTIFICATION_STATUS_ERROR, NOTIFICATION_STATUS_SUCCESS, NotificationStatus } from '../types/Notification';

export function* workListsSaga(): Generator {
  yield all([
    takeLatest(EXPORT_WORKLIST_REQUEST, exportWorkListSaga)
  ])
}

const ExportWorklistPipeline = "export-worklist"

function* exportWorkListSaga(action: ExportWorkListRequestAction): Generator<any, any, any> {
  // First we save the worklist
  const updateWorkListAction = updatePublicWorklist(action.worklist.id, action.worklist.data)

  yield put(forwardTx(action, updateWorkListAction))

  const updateWorkListResult = yield race({
    success: take(action => action.type === UPDATE_OBJECT_SUCCESS && action.object?.id === updateWorkListAction.id && action.txId === action.txId),
    failure: take(action => action.type === UPDATE_OBJECT_FAILURE && action.object?.id === updateWorkListAction.id && action.txId === action.txId),
  })

  if (updateWorkListResult.failure) {
    yield put(forwardTx(action, { type: EXPORT_WORKLIST_FAILURE, error: updateWorkListResult.failure.error } as any))
    return
  }

  // Then we run the "export-worklist" pipeline

  const idToken = yield select(selectIdToken)
  const client = new FileServerClient(Config.fileServerURL, idToken)
  const filesystems: Filesystem[] = yield call(client.queryFilesystems)

  const input = {
    worklists: [action.worklist.id],
    filesystems: filesystems.filter(fs => fs.metadata?.worklistExportFormat !== undefined).map(fs => fs.name),
  };

  const runPipelineAction = runPipeline(ExportWorklistPipeline, input);

  yield put(forwardTx(action, runPipelineAction))

  const runPipelineResult = yield race({
    success: take(action => action.type === RUN_PIPELINE_SUCCESS && action.name === ExportWorklistPipeline && action.txId === action.txId),
    failure: take(action => action.type === RUN_PIPELINE_FAILURE && action.name === ExportWorklistPipeline && action.txId === action.txId),
  })

  if (runPipelineResult.success) {
    yield put(forwardTx(action, { type: EXPORT_WORKLIST_SUCCESS } as any))
    yield put(addNotification({ status: NOTIFICATION_STATUS_SUCCESS, message: "Feuille de travail exportée !" }))
    return
  }

  if (runPipelineResult.failure) {
    yield put(forwardTx(action, { type: EXPORT_WORKLIST_FAILURE, error: runPipelineResult.failure.error } as any))
    yield put(addNotification({ status: NOTIFICATION_STATUS_ERROR, message: "Erreur lors de l'export de la feuille de travail !" }))
    return
  }

  // Should never happen
  yield put(forwardTx(action, { type: EXPORT_WORKLIST_FAILURE, error: new Error("Saga finished unexpectedly !") } as any))
}
