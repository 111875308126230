import successIcon from '../resources/success.svg'
import warningIcon from '../resources/warning.svg'
import errorIcon from '../resources/error.svg'

export const NOTIFICATION_STATUS_SUCCESS = 'success'
export const NOTIFICATION_STATUS_WARNING = 'warning'
export const NOTIFICATION_STATUS_ERROR = 'error'

export interface NotificationBase {
  status: string
  message: string
  detail?: string
}

export interface CustomNotification extends NotificationBase {
  id: number
  date: Date
}

export const NotificationStatus = {
  success: NOTIFICATION_STATUS_SUCCESS,
  warning: NOTIFICATION_STATUS_WARNING,
  error: NOTIFICATION_STATUS_ERROR
}

export const NotificationIcons = {
  success: successIcon,
  warning: warningIcon,
  error: errorIcon
}
