import { Action } from 'redux'
import {
  UserLoginRequest,
  UserLoginCheck,
  USER_LOGIN_REQUEST_SUCCESS,
  USER_LOGIN_CHECK_SUCCESS,
  REFRESH_ACCESS_TOKEN_SUCCESS,
  RefreshAccessTokenSuccess,
  REFRESH_ACCESS_TOKEN_FAILURE,
  USER_LOGOUT_REQUEST_SUCCESS,
  UserLogoutRequest,
  USER_LOGIN_INFO_REQUEST_SUCCESS,
  UserInfoRequestAction
} from '../actions/session'
import { PatientsPreferences, User } from '../types/Session'
import { SWITCH_TENANT_REQUEST_SUCCESS, SwitchTenantAction } from '../actions/tenant'

export interface SessionState {
  isLoggedIn: boolean
  idToken: string
  refreshToken: string
  accessToken: string
  user: User|null
  patientsPreferences: PatientsPreferences
}

const defaultState: SessionState = {
  isLoggedIn: false,
  idToken: '',
  refreshToken: '',
  accessToken: '',
  user: null,
  patientsPreferences: {
    byInlogID: {}
  }
}

export function sessionReducer (state: SessionState = defaultState, action: Action): SessionState {
  switch (action.type) {
    case USER_LOGIN_REQUEST_SUCCESS:
      return handleClientLoginRequestSuccess(state, action as UserLoginRequest)
    case USER_LOGIN_CHECK_SUCCESS:
      return handleClientLoginCheckSuccess(state, action as UserLoginCheck)
    case REFRESH_ACCESS_TOKEN_SUCCESS:
      return handleRefreshAccessTokenSuccess(state, action as RefreshAccessTokenSuccess)
    case REFRESH_ACCESS_TOKEN_FAILURE:
      return handleRefreshAccessTokenFailure(state, action)
    case USER_LOGOUT_REQUEST_SUCCESS:
      return handleClientLogoutSuccess(state, action as UserLogoutRequest)
    case USER_LOGIN_INFO_REQUEST_SUCCESS:
      return handleLoginInfoSuccess(state, action as UserInfoRequestAction)
    case SWITCH_TENANT_REQUEST_SUCCESS:
      return switchTenantSucess(state, action as SwitchTenantAction)
  }

  return state
}

function handleClientLoginRequestSuccess (state: SessionState, action: UserLoginRequest): SessionState {
  return {
    ...state
  }
}

function handleClientLogoutSuccess (state: SessionState, action: UserLogoutRequest): SessionState {
  return {
    ...state,
    isLoggedIn: false,
    idToken: '',
    refreshToken: '',
    accessToken: '',
    user: null,
    patientsPreferences: {
      byInlogID: {}
    }
  }
}

function handleClientLoginCheckSuccess (state: SessionState, action: UserLoginCheck): SessionState {
  return {
    ...state,
    isLoggedIn: action.result.isLoggedIn,
    idToken: action.result.idToken,
    accessToken: action.result.accessToken,
    refreshToken: action.result.refreshToken,
    user: action.result.user
  }
}

function handleRefreshAccessTokenSuccess (state: SessionState, action: RefreshAccessTokenSuccess): SessionState {
  return {
    ...state,
    accessToken: action.accessToken,
    refreshToken: action.refreshToken
  }
}

function handleRefreshAccessTokenFailure (state: SessionState, action: Action): SessionState {
  return {
    ...state,
    isLoggedIn: false,
    idToken: '',
    refreshToken: '',
    accessToken: '',
    user: null,
    patientsPreferences: {
      byInlogID: {}
    }
  }
}

function handleLoginInfoSuccess (state: SessionState, action: UserInfoRequestAction): any {
  const newState = { ...state }
  if (newState.user != null) {
    newState.user.roles = action.result.Data.User.Roles
    newState.user.subject = action.result.Data.User.Subject
    newState.user.tenant = action.result.Data.Tenant
  }
  return newState
}

function switchTenantSucess (state: SessionState, action: SwitchTenantAction): any {
  const newState = { ...state }
  if (newState.user != null) {
    newState.user.tenant = action.tenant
  }
  return newState
}
