import { Action } from 'redux'
import { AuthzAction, AuthzResource } from '../util/authz'

export const CHECK_AUTHZ_REQUEST = 'CHECK_AUTHZ_REQUEST'
export const CHECK_AUTHZ_REQUEST_SUCCESS = 'CHECK_AUTHZ_REQUEST_SUCCESS'
export const CHECK_AUTHZ_REQUEST_FAILURE = 'CHECK_AUTHZ_REQUEST_FAILURE'

export interface CheckAuthzAction extends Action {
  authzAction: AuthzAction
  authzResource: AuthzResource
}

export interface CheckAuthzActionSuccess extends Action {
  result: {
    allowed: boolean
    authzAction: AuthzAction
    authzResource: AuthzResource
  }
}

export function checkAuthz (authzAction: AuthzAction, authzResource: AuthzResource): CheckAuthzAction {
  return {
    type: CHECK_AUTHZ_REQUEST,
    authzAction: authzAction,
    authzResource: authzResource
  }
};
