import { RootState } from '../reducers/root'
import { SessionState } from '../reducers/session'

export function selectSession(state: RootState): SessionState {
  return state.session
}

export function selectAccessToken(state: RootState): string {
  return state.session.accessToken
}

export function selectRefreshToken(state: RootState): string {
  return state.session.refreshToken
}

export function selectIdToken(state: RootState): string {
  return state.session.idToken
}

export function selectIsLoggedIn(state: RootState): boolean {
  return state.session.isLoggedIn
}

export function selectCurrentTenant(state: RootState): string | null {
  return (state.session.user != null) ? state.session.user.tenant : null
}
export function selectUserName(state: RootState): string | null {
  return (state.session.user != null) ? state.session.user.subject : null
}
