import React, { FunctionComponent } from 'react'

export interface LoaderProps {
  embedded?: boolean
}

export const Loader: FunctionComponent<LoaderProps> = ({ embedded }) => {
  return (
    <div className="app-loader" style={{ position: embedded === true ? 'initial' : 'fixed' }}></div>
  )
}
