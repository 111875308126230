import { Action } from 'redux'

export const UI_TOGGLE_PROP = 'UI_TOGGLE_PROP'

export interface UIAction extends Action {
  method: string
  params: any
}

export interface ToggleAction extends UIAction {
  prop: 'light'|'patientCardFolded'
}

export function toggleProp (prop: 'light'|'patientCardFolded'): any {
  return { type: UI_TOGGLE_PROP, prop }
}
