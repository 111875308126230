import { put, take, select, race } from 'redux-saga/effects'

import {
  queryPublicPatients
} from '../actions/patient'
import { selectIdToken } from '../selectors/session'

export function* rehydrateSaga(): Generator<any, any, any> {
  // Check if we have an access token available
  const accessToken = yield select(selectIdToken)
  if (accessToken !== '') {
    // Load patients list
    yield (put(queryPublicPatients({}, { hydrate: true })))
  }
}
