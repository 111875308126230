import { Action } from 'redux'

export const CRISTAL_DATA_REQUEST = 'CRISTAL_DATA_REQUEST'
export const CRISTAL_DATA_SUCCESS = 'CRISTAL_DATA_SUCCESS'
export const CRISTAL_DATA_FAILURE = 'CRISTAL_DATA_FAILURE'

export const CRISTAL_HORIZONTAL_MAX_TOGGLE_REQUEST = 'CRISTAL_HORIZONTAL_MAX_TOGGLE_REQUEST'
export const CRISTAL_HORIZONTAL_MAX_TOGGLE_SUCCESS = 'CRISTAL_HORIZONTAL_MAX_TOGGLE_SUCCESS'
export const CRISTAL_HORIZONTAL_MAX_TOGGLE_FAILURE = 'CRISTAL_HORIZONTAL_MAX_TOGGLE_FAILURE'

export interface CristalAction extends Action {
  method: string
  params: any
}

export interface CristalSuccessAction extends CristalAction {
  result: string
}

export interface ToggleHorizontalMaxSuccessAction extends CristalAction {
  result: any
}

export function getCristalDataFromJSON () {
  return { type: CRISTAL_DATA_REQUEST }
}

export function toggleHorizontalMaximize () {
  return { type: CRISTAL_HORIZONTAL_MAX_TOGGLE_REQUEST }
}
