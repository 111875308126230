import { Action } from "redux";
import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  AddNotificationAction,
  RemoveNotificationAction,
} from "../actions/notification";
import { CustomNotification } from "../types/Notification";

export interface NotificationState {
  byId: IndexedNotification;
}

export interface IndexedNotification {
  [key: string]: CustomNotification;
}

const defaultState = {
  byId: {},
};

export function notificationReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return handleAddNotification(state, action as AddNotificationAction);
    case REMOVE_NOTIFICATION:
      return handleRemoveNotification(
        state,
        action as RemoveNotificationAction
      );
  }

  return state;
}

function handleAddNotification(
  state: NotificationState,
  action: AddNotificationAction
) {
  const notification = action.notification as CustomNotification;
  const date = new Date();
  notification.date = date;
  notification.id = date.getTime();

  return {
    ...state,
    byId: {
      ...state.byId,
      [notification.id]: notification,
    },
  };
}
function handleRemoveNotification(
  state: NotificationState,
  action: RemoveNotificationAction
) {
  const newState = {
    ...state,
    byId: {
      ...state.byId,
    },
  };
  delete newState.byId[action.notificationId];

  return newState;
}
