import { Action } from "redux";
import { AuditLog } from "../types/Audit";
import {
  AUDIT_REQUEST_SUCCESS,
  AuditLogResult,
  GetAuditLogSuccessAction,
} from "../actions/audit";

export interface AuditState {
  byId: IndexedAudit;
  total: number;
}

const defaultState = {
  byId: {},
  total: 0,
};

export interface IndexedAudit {
  [key: string]: AuditLog;
}

export function auditReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case AUDIT_REQUEST_SUCCESS:
      return handleAuditLog(state, action as GetAuditLogSuccessAction);
  }

  return state;
}

function handleAuditLog(state: AuditState, action: GetAuditLogSuccessAction) {
  const result = action.result.Data as AuditLogResult;
  const logsResult = result.Decisions;
  const logs: { [byId: string]: AuditLog } = {};
  logsResult.forEach((log: AuditLog) => {
    logs[log.ID] = log;
  });
  return {
    ...state,
    byId: logs,
    total: result.Total,
  };
}
