import { asDate } from './date'
import { InvalidRequestError, ServerError } from './error'
import { EventObject } from './events'
import { fetchWithCredentials } from './fetch'
import { assertResponse } from './response'

export enum PrivacyDomain {
  Public = 'public',
  Private = 'private'
}

export enum AggregatorToken {
  And = 'and',
  Or = 'or'
}

export enum FilterToken {
  Eq = 'eq',
  Neq = 'neq',
  Gte = 'gte',
  Gt = 'gt',
  Lt = 'lt',
  Lte = 'lte',
  Like = 'like',
  Exist = 'exist'
}

export type FilterOperator = {
  [op in FilterToken]?: {
    [prop: string]: any
  }
}

export type FilterAggregator = {
  [op in AggregatorToken]?: ObjectFilter[]
}

export type ObjectFilter = FilterOperator | FilterAggregator

export interface QueryObjectsOptions {
  limit?: number
  offset?: number
  hydrate?: boolean
  before?: string
  after?: string
}

export interface ObjectHeader {
  id: string
  createdAt: Date
  updatedAt: Date
}

export interface Object<T = any> extends ObjectHeader {
  data: T
}

export type StoreObject<T = any> = Object<T>;

export class ObjectServerClient {
  baseUrl: string
  idToken: string

  constructor(baseUrl: string, idToken: string) {
    this.baseUrl = baseUrl
    this.idToken = idToken

    // Force bind methods to instance
    this.queryObjects = this.queryObjects.bind(this)
    this.fetchObject = this.fetchObject.bind(this)
    this.createObject = this.createObject.bind(this)
    this.updateObject = this.updateObject.bind(this)
    this.deleteObject = this.deleteObject.bind(this)
    this.fetchObjectEvents = this.fetchObjectEvents.bind(this)
  }

  async queryObjects(namespace: string, privacyDomain: PrivacyDomain, filter?: ObjectFilter, options?: QueryObjectsOptions): Promise<(ObjectHeader | Object)[]> {
    let queryString: string[] = []
    if (filter !== undefined) {
      queryString.push(`filter=${encodeURIComponent(JSON.stringify(filter))}`)
    }

    if (options !== undefined) {
      if (options.hydrate) {
        queryString.push(`hydrate=${encodeURIComponent(true)}`)
      }

      if (options.limit && options.limit > 0) {
        queryString.push(`limit=${encodeURIComponent(options.limit)}`)
      }

      if (options.offset && options.offset >= 0) {
        queryString.push(`offset=${encodeURIComponent(options.offset)}`)
      }
    }

    const url = `${this.baseUrl}/api/v1/${namespace}/${privacyDomain}?${queryString.join('&')}`
    const res = await fetchWithCredentials(this.idToken, url)
    const result = await res.json()

    return result.Data.Objects.map(item => itemToObject(item))
  }

  async fetchObject(namespace: string, privacyDomain: PrivacyDomain, id: string): Promise<Object> {
    const url = `${this.baseUrl}/api/v1/${namespace}/${privacyDomain}/${id}`
    const res = await fetchWithCredentials(this.idToken, url)
    const result = await res.json()

    assertResponse(res)

    return itemToObject(result.Data.Object) as Object
  }

  async createObject(namespace: string, privacyDomain: PrivacyDomain, data: any): Promise<Object> {
    const url = `${this.baseUrl}/api/v1/${namespace}/${privacyDomain}`
    const res = await fetchWithCredentials(this.idToken, url, {
      method: 'POST',
      body: JSON.stringify(data)
    })

    const result = await res.json()

    assertResponse(res, result)

    return itemToObject(result.Data.Object) as Object
  }

  async updateObject(namespace: string, privacyDomain: PrivacyDomain, id: string, data: any, partial: boolean = false): Promise<Object> {
    const url = `${this.baseUrl}/api/v1/${namespace}/${privacyDomain}/${id}${partial ? '?partial=true' : ''}`
    const res = await fetchWithCredentials(this.idToken, url, {
      method: 'PUT',
      body: JSON.stringify(data)
    })
    const result = await res.json()

    assertResponse(res, result)

    return itemToObject(result.Data.Object) as Object
  }

  async deleteObject(namespace: string, privacyDomain: PrivacyDomain, id: string): Promise<void> {
    const url = `${this.baseUrl}/api/v1/${namespace}/${privacyDomain}/${id}`
    const res = await fetchWithCredentials(this.idToken, url, {
      method: 'DELETE'
    })
    await res.text()

    assertResponse(res)
  }

  async fetchObjectEvents(namespace: string, privacyDomain: PrivacyDomain, id: string): Promise<EventObject[]> {
    const url = `${this.baseUrl}/api/v1/${namespace}/${privacyDomain}/${id}/_events`
    const res = await fetchWithCredentials(this.idToken, url)
    const result = await res.json()

    assertResponse(res, result)

    return result.Data.Events
  }
}

function itemToObjectHeader(item: any): ObjectHeader {
  return {
    id: item.ID,
    createdAt: asDate(item.CreatedAt),
    updatedAt: asDate(item.UpdatedAt)
  }
}

function itemToObject(item: any): Object | ObjectHeader {
  const obj: Object | ObjectHeader = {
    ...itemToObjectHeader(item),
  }

  if (item.Data) {
    (obj as Object).data = item.Data
  }

  return obj
}
