import { RootState } from '../reducers/root';
import { ObjectHeader, PrivacyDomain, StoreObject } from '../util/objects';

export const selectObjectAttrIndex = (state: RootState, namespace: string, domain: PrivacyDomain, attr: string): { [value: string]: string } | undefined => {
    return state.objects.byNamespace[namespace]?.byDomain[domain]?.byAttr[attr]
}

export const selectObjectById = <T = any>(state: RootState, namespace: string, domain: PrivacyDomain, objectId: string): StoreObject<T> | ObjectHeader | undefined => {
    const objects = state.objects.byNamespace[namespace]?.byDomain[domain]?.byId;
    if (!objects) return

    return objects[objectId]
}

export const selectObjectByAttr = <T = any>(state: RootState, namespace: string, domain: PrivacyDomain, attr: string, value: any): StoreObject<T> | ObjectHeader | undefined => {
    const index = selectObjectAttrIndex(state, namespace, domain, attr)
    if (!index) return

    const objectId = index[value]
    if (!objectId) return

    return selectObjectById<T>(state, namespace, domain, objectId)
}