import { Config } from '../config'
import { fetchWithCredentials } from './fetch'
import { assertResponse } from './response'

export const loadTenantsDataRequest = async (idToken: string, tenant?: string): Promise<Response> => {
  const url = tenant !== undefined ? `${Config.authzServerURL}/api/v1/tenants/${tenant}` : `${Config.authzServerURL}/api/v1/tenants/`
  return await fetchWithCredentials(idToken, url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(async response => {
      const result = await response.json()
      assertResponse(response, result)
      return result
    })
    .then(function (response) {
      return response
    })
}

export const loadRoleRequest = async (idToken: string, tenant: string, subject: string): Promise<Response> => {
  const url = `${Config.authzServerURL}/api/v1/tenants/${tenant}/subjects/${subject}/roles`
  return await fetchWithCredentials(idToken, url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(async response => {
      const result = await response.json()
      assertResponse(response, result)
      return result
    })
    .then(function (response) {
      return response
    })
}

export const addRoleRequest = async (idToken: string, tenant: string, subject: string, role: string): Promise<Response> => {
  const url = `${Config.authzServerURL}/api/v1/tenants/${tenant}/subjects/${subject}/roles`
  return await fetchWithCredentials(idToken, url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      Add: [role]
    })
  })
    .then(async response => {
      const result = await response.json()
      assertResponse(response, result)
      return result
    })
    .then(function (response) {
      return response
    })
}

export const deleteRoleRequest = async (idToken: string, tenant: string, subject: string, role: string): Promise<Response> => {
  const url = `${Config.authzServerURL}/api/v1/tenants/${tenant}/subjects/${subject}/roles`
  return await fetchWithCredentials(idToken, url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      Remove: [role]
    })
  })
    .then(async response => {
      const result = await response.json()
      assertResponse(response, result)
      return result
    })
    .then(function (response) {
      return response
    })
}

export const switchTenantRequest = async (idToken: string, tenant: string): Promise<Response> => {
  const url = `${Config.authzServerURL}/api/v1/session/tenant`
  return await fetchWithCredentials(idToken, url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      tenant: tenant
    })
  })
    .then(async response => {
      const result = await response.json()
      assertResponse(response, result)
      return result
    })
    .then(function (response) {
      return response
    })
}
