import { Action } from "redux";
import {
  SEARCH_OBJECT_REQUEST_SUCCESS,
  SearchResult,
  SearchResultAction,
} from "../actions/search";

export interface SearchState {
  results: SearchResult[];
}

const defaultState = {
  results: [],
};

export function searchReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case SEARCH_OBJECT_REQUEST_SUCCESS:
      return handleSearchObjectRequestSuccess(
        state,
        action as SearchResultAction
      );
  }

  return state;
}

function handleSearchObjectRequestSuccess(
  state: SearchState,
  action: SearchResultAction
) {
  const newState = { ...state };

  newState.results = action.results;

  return newState;
}
